import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faDownload } from "@fortawesome/pro-regular-svg-icons";
import { DateBox, LoadPanel } from "devextreme-react";
import billingFormUtils, { BillingFormData, BillingStatus, documentObject } from "../Billing/BillingFormUtils";
import BillingService, { billingCycleStatus, billingStatus, logicAppPostRequest } from "../../services/BillingService";
import sharedUtils from "../grid/sharedUtilities";
import { BillingCyclesGridServiceInvoiceItem } from "../../services/BillingCyclesGridService";
import { AxiosResponse } from "axios";
import ManageDocumentsGridService from "../../services/ManageDocumentsGridService";
import { DateBoxOnValueChangedEvent } from "../../types/DevExtremeTypes";
import { NullableDate } from "../grid/AddEditPopUpUtilities";

interface SendToAccountsProps {
    invoiceId: number;
    billingData: BillingFormData;
    onSendToAccounts: (status: BillingStatus, statusId: string,date: NullableDate) => void;
    statusId: string;
    //invoiceSentDate: NullableDate;
    //syncDateFields: (date: NullableDate, type: string) => void;
    calculationsObject: any;
    viewDisable: boolean;
}

interface SendToAccountsState {
    loadPanelVisible: boolean;
    disableSentButton: boolean;
    disableSkipButton: boolean;
    statusIdOnClick: string;
    //billingFormData: BillingFormData;
    errorMessage: [];
}

class SendToAccounts extends React.Component<SendToAccountsProps> {
    state: SendToAccountsState;
    BillingService: BillingService;
    billingFormUtils: billingFormUtils;
    sharedUtils: sharedUtils;
    documentService: ManageDocumentsGridService;

    constructor(props: SendToAccountsProps) {
        super(props);
        this.BillingService = new BillingService();
        this.billingFormUtils = new billingFormUtils();
        this.sharedUtils = new sharedUtils();
        this.state = {
            loadPanelVisible: false,
            disableSkipButton: this.props.viewDisable ? true : false,
            disableSentButton: this.props.viewDisable ? true : false,
            statusIdOnClick: "",
            //billingFormData: this.props.billingData,
            errorMessage: [],
        };
        this.documentService = new ManageDocumentsGridService();
    }

    // //Trigger the onChange function for "send to accunts Date" field which is to be synchronised with the form.
    // handleChangeSentAccountsDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
    //     this.setState({
    //         billingFormData: {
    //             ...this.state.billingFormData,
    //             sentToAccountsDate: dxValueChange.value,
    //         },
    //     });
    //     //this.props.syncDateFields(dxValueChange.value, "AccountSent");
    // };

    //Function which is invoked when the "Produce" as well as "Sent" button is clicked.
    SkipToAccounts = (statusId: string, status: string) => {
        let data: BillingCyclesGridServiceInvoiceItem = this.billingFormUtils.initializeBillingCyclesGridServiceInvoiceItem();
        data.invoiceId = this.props.invoiceId ? this.props.invoiceId.toString() : "";
        data.clientId = this.props.billingData.clientId;
        data.client = this.props.billingData.client;
        data.statusId = statusId;
        data.status = status;
        data.periodMatrixId = this.props.billingData.periodMatrixId;
        data.period = this.props.billingData.period;
        data.week = this.props.billingData.week;
        data.dateRangeFrom = this.props.billingData.dateRangeFrom
            ? this.sharedUtils.convertDateToString(this.props.billingData.dateRangeFrom)
            : "";
        data.dateRangeTo = this.props.billingData.dateRangeTo
            ? this.sharedUtils.convertDateToString(this.props.billingData.dateRangeTo)
            : "";
        data.tax =
            this.props.calculationsObject && this.props.calculationsObject.tax
                ? this.props.calculationsObject.tax.toString()
                : "";
        data.subTotal =
            this.props.calculationsObject && this.props.calculationsObject.subTotal
                ? this.props.calculationsObject.subTotal.toString()
                : "";
        data.total =
            this.props.calculationsObject && this.props.calculationsObject.total
                ? this.props.calculationsObject.total.toString()
                : "";
        data.invoiceDate = this.props.billingData.invoiceDate
            ? this.sharedUtils.convertDateToString(this.props.billingData.invoiceDate)
            : "";
        data.invoiceReference = this.props.billingData.invoiceReference
            ? this.props.billingData.invoiceReference.toString()
            : "";
        data.internalReference = this.props.billingData.internalReference
            ? this.props.billingData.internalReference.toString()
            : "";
        data.invoiceSentDate = this.props.billingData.invoiceSentDate
            ? this.sharedUtils.convertDateToString(this.props.billingData.invoiceSentDate)
            : "";
        data.sentToAccountsDate = "";//if skip no date
        data.invoiceDueDate = this.props.billingData.invoiceDueDate
            ? this.sharedUtils.convertDateToString(this.props.billingData.invoiceDueDate)
            : "";

        data.invoicePaidDate = this.props.billingData.invoicePaidDate
            ? this.sharedUtils.convertDateToString(this.props.billingData.invoicePaidDate)
            : "";
        data.invoiceAmount = this.props.billingData.invoiceAmount
            ? this.props.billingData.invoiceAmount.toString()
            : "";
        data.description = this.props.billingData.description ? this.props.billingData.description.toString() : "";
        data.actionType = "Save";
        data.invoiceServiceMappingList = this.props.billingData.invoiceServiceMappingList;

        this.setState({
            //gridData: data,
            loadPanelVisible: true,
            statusIdOnClick: statusId,
            // billingFormData: {
            //     ...this.state.billingFormData,
            //     sentToAccountsDate: data.sentToAccountsDate,
            // }
        });
        this.onSubmit(data);
    };

    SendToAccounts = (statusId: string, status: string) => {
        this.setState({
            loadPanelVisible: true,
            statusIdOnClick: statusId,
        }, () => {
           //API call for send to accounts
           this.BillingService.sendSalesInvoice(this.props.invoiceId)
        .then(this.handleSuccessOnSent)
        .catch(this.handleFailure);
        });
    }
    handleSuccessOnSent = (response: AxiosResponse<any>) => {
        //const { invoiceId } = this.props;
        const { statusIdOnClick } = this.state;
        const today = new Date();
        this.setState(
            (prevState: SendToAccountsState) => ({
                loadPanelVisible: false,
                disableSkipButton: statusIdOnClick == billingCycleStatus.BillNotSentToAccounts ? true : (this.props.billingData.sentToAccountsDate ? true : false),
                disableSentButton: this.props.billingData.sentToAccountsDate ? true : false,
                errorMessage: [],
            }),
            () => {
                this.props.onSendToAccounts(BillingStatus.BillSentToAccounts, billingCycleStatus.BillSentToAccounts,today)
            });
    };
    onSubmit = (billingRowData: BillingCyclesGridServiceInvoiceItem) => {
        this.BillingService.billingDataService(billingRowData).then(this.handleSuccess).catch(this.handleFailure);
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        //const { invoiceId } = this.props;
        const { statusIdOnClick } = this.state;

        this.setState(
            (prevState: SendToAccountsState) => ({
                loadPanelVisible: false,
                disableSkipButton: statusIdOnClick == billingCycleStatus.BillNotSentToAccounts ? true : (this.props.billingData.sentToAccountsDate ? true : false),
                disableSentButton: this.props.billingData.sentToAccountsDate ? true : false,
                errorMessage: [],
            }),
            () => {
                this.props.onSendToAccounts(BillingStatus.BillNotSentToAccounts, billingCycleStatus.BillNotSentToAccounts,null)
            });
        // if (statusIdOnClick == billingCycleStatus.BillPackRequested) {
        //     let postObject: logicAppPostRequest = {
        //         InvoiceId: invoiceId ? invoiceId.toString() : "",
        //     };
        //     this.BillingService.triggerLogicAppEndpoint(postObject);
        // }
    };

    handleFailure = (error: any) => {
        this.setState({loadPanelVisible: false});
        var { statusIdOnClick } = this.state;
        var respMessage: string = "SendSalesInvoice failed";

        if (!this.BillingService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
        this.setState({
            errorMessage: error.response !== null ? respMessage : null,
            loadPanelVisible: false,
            disableSentButton: false,
            disableSkipButton: false
        });
    };

    //Inside the update function, when the Sent date is changed in the form, it would reflect here and vice versa.
    componentDidUpdate = (prevProps: SendToAccountsProps) => {
        if (this.props.statusId && this.props.statusId != prevProps.statusId) {
            let disableskip = this.props.statusId == billingCycleStatus.BillNotSentToAccounts ? true : (this.props.billingData.sentToAccountsDate ? true : false);
            let disableSent = this.props.billingData.sentToAccountsDate ? true : false;
                this.setState({
                disableSkipButton: disableskip,
                disableSentButton: disableSent
            })

        }
    };
    render() {
        let { statusId } = this.props;
        let { disableSentButton, disableSkipButton } = this.state;
        let buttonSentText = 'SEND TO ACCOUNTS';
        if (this.props.billingData.sentToAccountsDate) {
            buttonSentText = 'SENT';
        }

        // let sentButtonCssClass: string =
        //     disableSentButton ||
        //         (statusId == billingCycleStatus.BillSentToAccounts ||
        //             statusId == billingCycleStatus.BillNotSentToAccounts ||
        //             statusId == billingCycleStatus.BillPaid ||
        //             statusId == billingCycleStatus.BillNotPaid ||
        //             statusId == billingCycleStatus.BillQueried)
        //         ? "btn disabledCycleButtonColor btn--large"
        //         : "btn saveCycleButtonColor btn--large";

        let sentButtonCssClass: string = (
            disableSentButton || 
            this.props.billingData.sentToAccountsDate ||
            statusId == billingCycleStatus.BillSentToAccounts || 
            statusId == billingCycleStatus.BillNotSentToAccounts || 
            statusId == billingCycleStatus.BillPaid ||
            statusId == billingCycleStatus.BillNotPaid||
            statusId == billingCycleStatus.BillQueried||
            statusId == billingCycleStatus.BillPackFailed)
            ? "btn disabledCycleButtonColor btn--large"
            : "btn saveCycleButtonColor btn--large";
        let skipButtonCssClass: string = (disableSkipButton || this.props.billingData.sentToAccountsDate || statusId == billingCycleStatus.BillNotSentToAccounts)
            ? "btn disabledCycleButtonColor btn--large"
            : "btn saveCycleButtonColor btn--large";


        // let disableAccountSentButton: boolean =
        //     disableSentButton ||
        //         statusId == billingCycleStatus.BillSentToAccounts ||
        //         statusId == billingCycleStatus.BillNotSentToAccounts ||
        //         statusId == billingCycleStatus.BillPaid ||
        //         statusId == billingCycleStatus.BillNotPaid ||
        //         statusId == billingCycleStatus.BillQueried
        //         ? true
        //         : false;
        let disableAccountSentButton: boolean = (disableSentButton || this.props.billingData.sentToAccountsDate) ? true : false;
        let disableAccountSkipButton: boolean = (disableSkipButton || this.props.billingData.sentToAccountsDate || statusId == billingCycleStatus.BillNotSentToAccounts) ? true : false;

        return (
            <div className="card my-3">
                <div className="card-body">
                    <h4>
                        <b>Send To Accounts</b>
                    </h4>
                    <>
                        <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                        {this.state.errorMessage ? (
                            <span className="unscheduled-shift">
                                <ul>
                                   <li>{this.state.errorMessage}</li>
                                </ul>
                            </span>
                        ) : (
                            <></>
                        )}
                        <div className="row align-items-center">
                            <div className="col-2">
                                <button
                                    className={sentButtonCssClass}
                                    type="button"
                                    onClick={(e: React.MouseEvent) => {
                                        e.preventDefault();
                                        this.SendToAccounts(billingCycleStatus.BillSentToAccounts,
                                            billingStatus.BillSentToAccounts)
                                        //TO DO New API
                                    }}
                                    disabled={disableAccountSentButton}
                                >
                                    {buttonSentText}
                                </button>
                            </div>
                            <div className="col-2">
                                <button
                                    className={skipButtonCssClass}
                                    type="button"
                                    onClick={(e: React.MouseEvent) => {
                                        e.preventDefault();
                                        this.SkipToAccounts(
                                            billingCycleStatus.BillNotSentToAccounts,
                                            billingStatus.BillNotSentToAccounts
                                        );
                                    }}
                                    disabled={disableAccountSkipButton}
                                >
                                    SKIP
                                </button>
                            </div>

                            <div className="col-4 text-right">Sent Date</div>
                            <div className="col-4">
                                <DateBox
                                    displayFormat="dd/MM/yyyy"
                                    readOnly={true}
                                    //onValueChanged={this.handleChangeSentAccountsDate}
                                    value={
                                        this.props.billingData.sentToAccountsDate
                                            ? new Date(this.props.billingData.sentToAccountsDate)
                                            : undefined
                                    }
                                    useMaskBehavior={true}
                                    disabled={this.props.viewDisable ? true : false}
                                />
                            </div>
                        </div>
                    </>
                </div>
            </div>
        );
    }
}
export default SendToAccounts;
