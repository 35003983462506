import { AxiosResponse } from "axios";
import { _get, _post, _postBatchRequest, _aiTraceAsError } from "./BaseApiService";

// Endpoints
const EDIT_VIEW_API = "Invoice/V2";
const PAY_DATE_API = "BillableItem/UpdatePayDates";
const INVOICE_LOGIC_APP_ENDPOINT = "invoicedocument";
const SEND_SALES_INVOICE = "Invoice/SendSalesInvoice"

export interface invoiceServices {
    invoiceId: string;
    serviceTypeLookupId: string;
    value: string;
}

// Interfaces
export interface BillingFormSubmitRequest {
    invoiceId: string;
    clientId: string;
    client: string;
    statusId: string;
    status: string;
    periodMatrixId: string;
    period: string;
    week: string;
    dateRangeFrom: string;
    dateRangeTo: string;
    tax: string;
    subTotal: string;
    total: string;
    invoiceDate: string;
    invoiceReference: string;
    invoiceSentDate: string;
    invoiceDueDate: string;
    invoicePaidDate: string;
    invoiceAmount: string;
    description: string;
    actionType: string;
    invoiceServiceMappingList: invoiceServices[];
    internalReference: string;
}

export const billingCycleStatus = {
    Draft: "0",
    Pending: "1",
    ReadyForBilling: "10",
    BillChecked: "20",
    BillPackRequested: "25",
    BillPackReady: "30",
    BillPackSent: "40",
    BillSentToAccounts:"45",
    BillNotSentToAccounts:"46",
    BillPaid: "50",
    BillNotPaid: "60",
    BillQueried: "70",
    BillPackFailed: "31",
};

export const billingStatus = {
    Pending: "Pending",
    ReadyForBilling: "Ready For Billing",
    BillChecked: "Bill Checked",
    BillPackRequested: "Bill Pack Requested",
    BillPackReady: "Bill Pack Ready",
    BillPackSent: "Bill Pack Sent",
    BillSentToAccounts:"Bill Sent to Accounts",
    BillNotSentToAccounts:"Bill Not Sent to Accounts",
    BillPaid: "Bill Paid",
    BillNotPaid: "Bill Not Paid",
    BillQueried: "Bill Queried",
    BillPackFailed: "Bill Pack Failed",
};

export interface BillingFormResponse {
    invoiceId: string;
    clientId: string;
    client: string;
    statusId: string;
    status: string;
    periodMatrixId: string;
    period: string;
    week: string;
    dateRangeFrom: string;
    dateRangeTo: string;
    tax: string;
    subTotal: string;
    total: string;
    invoiceDate: string;
    invoiceReference: string;
    invoiceSentDate: string;
    invoiceDueDate: string;
    invoicePaidDate: string;
    invoiceAmount: string;
    description: string;
    actionType: string;
    invoiceServiceMappingList: invoiceServices[];
    internalReference: string;
}
//Object to trigger the Logic app Endpoint.
export interface logicAppPostRequest {
    InvoiceId: string;
}

/// REST services for the BillableItem resource.
class BillingService {
    // Retrieve all rows for the grid based on Id of row clicked on parent grid
    getBillingData(id: number) {
        return _get(`${EDIT_VIEW_API}/${id}`);
    }
    //Restart Button Click
    billingDataService(formData: object): Promise<AxiosResponse<any>> {
        return _post(EDIT_VIEW_API, formData);
    }
    sendSalesInvoice(id:number): Promise<AxiosResponse<any>> {
        return _post(`${SEND_SALES_INVOICE}/${id}`);
    }
    //Triggers off the Logic app Endpoint when the Billing cycle status is "Bill Pack Requested"
    triggerLogicAppEndpoint(formData: object): Promise<AxiosResponse<any>> {
        return _postBatchRequest(INVOICE_LOGIC_APP_ENDPOINT, formData);
    }
    // Billing Cycle update/Add request
    uploadEditAddData(editAddData: object): string {
        return "";
    }
    //update pay dates
    updatePayDates(formData: object): Promise<AxiosResponse<any>> {
        return _post(PAY_DATE_API, formData);
    }
    //#endregion
    traceAsErrorToAppInsights(message: string): boolean {
        return _aiTraceAsError(message);
    }
}

export default BillingService;
