import React, { MouseEvent } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { billingCycleStatus } from "../../services/BillingService";

//#region props
interface BillingStatusFlowProps extends WithTranslation {
    statusId: string;
    onRestartClicked: (isRestartClicked: boolean) => void;
    isRestartDisable: boolean;
}
//#endregion

//#region State
interface BillingStatusFlowState {
    statusId: string;
    prepareStateCssClass: string;
    lockShiftsStateCssClass: string;
    checkStateCssClass: string;
    produceStateCssClass: string;
    sentStateCssClass: string;
    accountsStateCssClass:string,
    paidStateCssClass: string;
    isRestartClicked: boolean;
}
//#endregion

// Component
class BillingStatusFlow extends React.Component<BillingStatusFlowProps> {
    state: BillingStatusFlowState;
    constructor(props: BillingStatusFlowProps) {
        super(props);

        this.state = {
            statusId: this.props.statusId,
            prepareStateCssClass: "",
            lockShiftsStateCssClass: "",
            checkStateCssClass: "",
            produceStateCssClass: "",
            sentStateCssClass: "",
            accountsStateCssClass:"",
            paidStateCssClass: "",
            isRestartClicked: false,
        };
    }

    updateCssClasses = () => {
        const standardItemCssClass = "status-flow__progress-bar__item";
        const highlightItemCssClass = "status-flow__progress-bar__item--highlighted";
        const statusId = this.state.statusId;

        this.setState({
            prepareStateCssClass:
                statusId == billingCycleStatus.Pending ||
                statusId == billingCycleStatus.ReadyForBilling ||
                statusId == billingCycleStatus.BillChecked ||
                statusId == billingCycleStatus.BillPackRequested ||
                statusId == billingCycleStatus.BillPackReady ||
                statusId == billingCycleStatus.BillPackFailed ||
                statusId == billingCycleStatus.BillPackSent ||
                statusId == billingCycleStatus.BillSentToAccounts ||
                statusId == billingCycleStatus.BillNotSentToAccounts ||
                statusId == billingCycleStatus.BillNotPaid ||
                statusId == billingCycleStatus.BillQueried ||
                statusId == billingCycleStatus.BillPaid
                    ? highlightItemCssClass
                    : standardItemCssClass,
            lockShiftsStateCssClass:
                statusId == billingCycleStatus.ReadyForBilling ||
                statusId == billingCycleStatus.BillChecked ||
                statusId == billingCycleStatus.BillPackRequested ||
                statusId == billingCycleStatus.BillPackReady ||
                statusId == billingCycleStatus.BillPackFailed ||
                statusId == billingCycleStatus.BillPackSent ||
                statusId == billingCycleStatus.BillSentToAccounts ||
                statusId == billingCycleStatus.BillNotSentToAccounts ||
                statusId == billingCycleStatus.BillNotPaid ||
                statusId == billingCycleStatus.BillQueried ||
                statusId == billingCycleStatus.BillPaid
                    ? highlightItemCssClass
                    : standardItemCssClass,
            checkStateCssClass:
                statusId == billingCycleStatus.BillChecked ||
                statusId == billingCycleStatus.BillPackRequested ||
                statusId == billingCycleStatus.BillPackReady ||
                statusId == billingCycleStatus.BillPackFailed ||
                statusId == billingCycleStatus.BillPackSent ||
                statusId == billingCycleStatus.BillSentToAccounts ||
                statusId == billingCycleStatus.BillNotSentToAccounts ||
                statusId == billingCycleStatus.BillNotPaid ||
                statusId == billingCycleStatus.BillQueried ||
                statusId == billingCycleStatus.BillPaid
                    ? highlightItemCssClass
                    : standardItemCssClass,
            produceStateCssClass:
                statusId == billingCycleStatus.BillPackReady ||
                statusId == billingCycleStatus.BillPackSent ||
                statusId == billingCycleStatus.BillSentToAccounts ||
                statusId == billingCycleStatus.BillNotSentToAccounts ||
                statusId == billingCycleStatus.BillNotPaid ||
                statusId == billingCycleStatus.BillQueried ||
                statusId == billingCycleStatus.BillPaid
                    ? highlightItemCssClass
                    : standardItemCssClass,
            sentStateCssClass:
                statusId == billingCycleStatus.BillPackSent ||
                statusId == billingCycleStatus.BillSentToAccounts ||
                statusId == billingCycleStatus.BillNotSentToAccounts ||
                statusId == billingCycleStatus.BillNotPaid ||
                statusId == billingCycleStatus.BillQueried ||
                statusId == billingCycleStatus.BillPaid
                    ? highlightItemCssClass
                    : standardItemCssClass,
            accountsStateCssClass:
                statusId == billingCycleStatus.BillSentToAccounts ||
                statusId == billingCycleStatus.BillNotSentToAccounts ||
                statusId == billingCycleStatus.BillNotPaid ||
                statusId == billingCycleStatus.BillQueried ||
                statusId == billingCycleStatus.BillPaid
                    ? highlightItemCssClass
                    : standardItemCssClass,
            paidStateCssClass: statusId == billingCycleStatus.BillPaid ? highlightItemCssClass : standardItemCssClass,
        });
    };

    componentDidUpdate(prevProps: BillingStatusFlowProps) {
        if (prevProps.statusId != this.props.statusId) {
            this.setState(
                {
                    statusId: this.props.statusId,
                },
                this.updateCssClasses
            );
        }
    }

    componentDidMount() {
        this.updateCssClasses();
    }
    onClickRestart = () => {
        this.setState(
            (prevState: BillingStatusFlowState) => ({
                isRestartClicked: !prevState.isRestartClicked,
            }),
            () => {
                this.props.onRestartClicked(this.state.isRestartClicked);
            }
        );
    };

    //#region render Component
    render() {
        return (
            <section className="status-flow">
                <ul className="status-flow__progress-bar">
                    <li className={this.state.prepareStateCssClass}>
                        <label>Prepare</label>
                    </li>
                    <li className={this.state.lockShiftsStateCssClass}>
                        <label>Lock Shifts</label>
                    </li>
                    <li className={this.state.checkStateCssClass}>
                        <label>Check</label>
                    </li>
                    <li className={this.state.produceStateCssClass}>
                        <label>Produce</label>
                    </li>
                    <li className={this.state.sentStateCssClass}>
                        <label>Sent</label>
                    </li>
                    <li className={this.state.accountsStateCssClass}>
                        <label>Accounts</label>
                    </li>
                    <li className={this.state.paidStateCssClass}>
                        <label>Paid</label>
                    </li>
                </ul>
                <button
                    className={
                        (this.props.isRestartDisable ? "disabled " : "") +
                        "btn btn--ghost--teal status-flow__restart-button"
                    }
                    type="button"
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                        this.onClickRestart();
                    }}
                    disabled={this.props.isRestartDisable}
                >
                    <span className="btn__icon"></span>
                    Restart
                </button>
            </section>
        );
    }
}

//#endregion

export default withTranslation()(BillingStatusFlow);
