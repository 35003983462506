import { AxiosResponse } from "axios";
import { _get, _postArray, _aiTraceAsError, _delete } from "./BaseApiService";
import { queryItem } from "./CostReportService";

// Endpoints
const SIMPLE_DATA_GRID_SERVICE_API = "billableitem";
const MATCH_POP_UP_GRID_API = "BillableItem/PaymentRequest";
const APPROVE_SHIFTS_API = "BillableItem/ApproveShifts";
const CONFIRM_SHIFTS_API = "BillableItem/ConfirmWork";
const DELETE_SHIFTS_API = "BillableItem";
const MANAGE_CLIENT_BILLING_CYCLES_API = "BillableItem/Invoice";
const BILLING_CYCLES_PREVIEW_API = "Billableitem/PreviewShifts";
const MANAGE_PAYMENT_CYCLES_API = "BillableItem/PaymentCycle";
const ARTIST_PAYMENT_API = "BillableItem/Payment";
const MANAGE_SHIFTS_API = "BillableItem";
const GIG_LIST_API = "Billableitem/Gigs";
const REPORTS_GRID_API = "billableitem/Invoice";
const SIMPLE_DATA_GRID_REMOTE_OPERATIONS_API = "billableitem/manageshift";

// Grid row
export interface SimpleGridServiceRowItem {
    billableItemId: number;
    date: string;
    client: string;
    provider: string;
    venue: string;
    service: string;
    role: string;
    name: string;
    identification_Number: string;
    cover: string;
    check_In_Out: string;
    shift: string;
    billable_Hours_Units: string;
    rate: string;
    cost: string;
    status: string;
    comments: string;
    billable_Start_Finish: string;
    checkin_Color: string;
    checkin_Font: string;
    checkOut_Color: string;
    checkOut_Font: string;
    status_Color: string;
    status_BackGroundColor: string;
    shift_Color: string;
    shift_Font: string;
    tenseFlag: string;
    provider_Pay_Amount_Local_Ccy: string;
    billable_Minutes: string;
    serviceTypeId: string;
    pay_Date: string;
    bill_Date: string;
    shiftCompleteAcknowledgment: boolean;
}

export interface PeriodGridTypeParams {
    serviceTypeId: string;
    periodNumber: string;
    periodWeek: string;
}

export const GridTypes = {
    billing: "Billing",
    billingPreview: "BillingPreview",
    payments: "Payments",
    EventUk: "EventUk",
    Artist: "Artist",
    Gig: "Gig",
    SimpleGrid: "SimpleGrid",
    ShiftMatrixGrid: "ShiftMatrixGrid",
    invoiceReport: "InvoiceReport",
    futureNegetiveShift: "FutureNegetiveShift",
    paymentsRequest: "PaymentRequest",
    residencyShifts: "ResidencyShifts",
    queryForm: "QueryForm",
    contractCleaningShifts: "ContractCleaningShifts",
    artistShiftMatrix: "ArtistShiftMatrix",
    providerShiftMatrix: "ProviderShiftMatrix",
    managePayDatesUnpaidShiftGrid : 'ManagePayDatesUnpaidShiftGrid',
    addShiftBillingGrid:'AddShiftBillingGrid'
};

export const paymentRequestGridTypes = {
    matchedPopUp: "matchedPopup"
}

export const GigStatusTypes = {
    pending: "Pending",
    awaiting_completion: "Awaiting Gig Completion",
    awaiting_authorization: "Awaiting Authorisation",
    payment_scheduled: "Payment Scheduled",
    payment_sent: "Payment Sent",
};
export const filterTypes = {
    security: "Security",
    entertainment: "Entertainment",
    cleaning: "Cleaning",
    miscellaneous: "Miscellaneous",
};

export interface ShiftMatrixRemoteGridRowItem {
    id: number,
    batchId: number,
    childRecordId: number,
    editAllowed: string,
    childRecordItemType: string,
    billableItemRelatedId: number,
    dateFrom: string,
    dateTo: string,
    clientAmount: string,
    venueName: string,
    venuePostCode: string,
    venueTownCity: string,
    role: string,
    name: string,
    idValue: string,
    cover: string,
    shift: string,
    billable_Hours_Units: string,
    billable_Minutes: string,
    timeDecimal: string,
    timeDiffIndicator: string,
    quantityDiffIndicator: string,
    originalBillableMinutes: string,
    rate: string,
    rate_Decimal: string,
    scheduleRate: string,
    originalRate:string,
    billableRate: string,
    cost: string,
    itemTypeId:string,
    invoiceId: string,
    paymentId: string,
    paymentCycleId: string,
    reason: string,
    internalComments: string,
    billable_Start_Finish: string,
    providerTaxApplicable: string,
    clientTaxApplicable: string,
    feeTaxApplicable: string,
    payDate: string,
    billDate: string,
    providerPayAmountAccrual: string,
    grossProfitAmountAccrual: string,
    clientBillableAmountAccrual: string,
    providerPayAmountLocalCurrency: string,
    providerPayAmountLocal: string,
    providerPayAmountTaxLocal: string,
    providerPayAmountTotalLocal: string,
    providerOriginalPayAmount: string,
    grossProfitAmountLocalCurrency: string,
    fee_Amount_Local_Ccy: string,
    fee_Tax_Local_Ccy: string,
    fee_Total_Local_Ccy:string,
    grossProfitPercentageLocal: string,
    clientBillableAmountLocalCurrency: string,
    clientBillableAmountWoAccrual: string,
    client_Billable_Amount_Local_Ccy: string,
    clientBillableAmountLocal: number,
    client_Billable_Tax_Local_Ccy: string,
    client_Billable_Total_Local_Ccy: string,
    clientOriginalBillableAmount: string,
    acceptDate: string,
    approvalDate: string,
    approved_By: string,
    accepted_By: string,
    nonStandard: string,
    status_Color: string,
    status_BackGroundColor: string,
    shift_Color: string,
    shift_Font: string,
    tenseFlag: string,
    gigTenseFlag: string,
    billableStatusLookupId: string,
    absent: string,
    unscheduled: string,
    shift_Start: string,
    shift_End: string,
    billable_Start: string,
    billable_Finish: string,
    originalBillableStartTime: string,
    originalBillableFinishTime: string,
    typeLookUpId: string,
    idTypeLookUpId: string,
    serviceTypeLookUpId: string,
    serviceSubTypeLookUpId: string,
    clientBusinessEntityId: string,
    providerBusinessEntityId: string,
    venueId: string,
    isAmend: string,
    noCharge: string,
    scheduled_Hours_Units: string,
    scheduledMinutes: string,
    otherDescription: string,
    requestApprover: string,
    requestDate: string,
    validationErrors: string,
    contractHours: string,
    performanceInformation: string,
    entertainmentDateInfo: string,
    address: string,
    reoccurance: string,
    venue_Phone: string,
    genre: string,
    additionalInformation: string,
    equipmentRequired: string,
    performanceInformationSetsNumber: string,
    performanceInformationSetsDuration: string,
    requestId: string,
    contacts: string,
    shiftCompleteAcknowledgment: boolean,
    shiftCompleteAcknowledgmentDateTime: string,
    expectedFee: string,
    billPeriod: string,
    rateDiffIndicator: string,
    hardApproval: string,
    holdPayment: string,
    accrualBillableItemBillDate: string,
    isAccrual: boolean,
    queries: [],
    feedback: {
        billableItemId: string,
        feedbackScore: string,
        feedbackComments: string,
        businessEntityId: string,
        feedbackType: string,
        validationErrors: string
    },
    accrual: [],
    paymentRequestID: string,
    paymentRequestStatusID: string,
    paymentRequestStatus: string,
    isUserAmendable: boolean,
    scheduledStartTime: string,
    scheduledFinishTime: string,
    billableStartTime: string,
    billableFinishTime:string,
    lastUpdatedOn: string,
    serviceSubTypeValue: string,
    typeValue: string,
    workerStatusId: string,
    workerStatusValue: string,
    clientBusinessEntity: {
        id: string,
        shortName: string
    },
    providerBusinessEntity: {
        id: string,
        shortName: string,
        professionalName: string,
    },
    billableStatusLookUp: {
        id: number,
        value: string,
        billableItem: []
    },
    serviceTypeLookUp: {
        id: string,
        value: string,
        parentMappingId: null,
        otherDescription: null
    },
    serviceSubTypeLookUp: {
        id: string,
        value: string,
        parentMappingId: null
    },
    typeLookUp: {
        id: number,
        value: string
    },
    billableItemType: {
        id: number,
        value: string
    },
    venue: {
        id: string,
        venueName: string
    },
    paymentRequest: null,
    paymentRequestStatusLookUp: null,
    statusText: string; //This node is not a part of the incoming object.
    filterStartDate: string; //This node is not a part of the incoming object
    filterEndDate: string; //This node is not a part of the incoming object,
}


export interface eventUkUserGridRowItem {
    billableItemId: string;
    date: string;
    dateTo: string;
    client: string;
    provider: string;
    venue: string;
    venueName: string;
    venueDetails: {
        houseNumber: string,
        venueName: string,
        onboarded: boolean,
    },
    service: string;
    role: string;
    name: string;
    identification_Number: string;
    cover: string;
    shift: string;
    billable_Hours_Units: string;
    billable_Minutes: string;
    rate: string;
    rate_Decimal: string;
    cost: string;
    status: string;
    comments: string;
    billable_Start_Finish: string;
    provider_Tax_Applicable: string;
    client_Tax_Applicable: string;
    fee_Tax_Applicable: string;
    pay_Date: string;
    bill_Date: string;
    provider_Pay_Currency_Local_Ccy: string;
    provider_Pay_Amount_Local_Ccy: string;
    provider_Pay_Tax_Local_Ccy: string;
    provider_Pay_Total_Local_Ccy: string;
    provider_Pay_Original_Amount_Local_Ccy: string;
    fee_Currency_Local_Ccy: string;
    fee_Amount_Local_Ccy: string;
    fee_Tax_Local_Ccy: string;
    fee_Total_Local_Ccy: string;
    fee_Percentage_Local_Ccy: string;
    client_Billable_Currency_Local_Ccy: string;
    client_Billable_Amount_Local_Ccy: string;
    client_Billable_Tax_Local_Ccy: string;
    client_Billable_Total_Local_Ccy: string;
    client_Billable_Original_Amount_Local_Ccy: string;
    accept_Date: string;
    approval_Date: string;
    non_Standard: string;
    status_Color: string;
    status_BackGroundColor: string;
    shift_Color: string;
    shift_Font: string;
    tenseFlag: string;
    billableStatusLookupId: string;
    absent: string;
    unscheduled: string;
    shift_Start: string;
    shift_End: string;
    billable_Start: string;
    billable_Finish: string;
    typeId: string;
    identificationTypeId: string;
    equipmentQuantity: string;
    serviceTypeId: string;
    serviceSubTypeId: string;
    serviceSubTypeValue: string;
    clientId: string;
    providerId: string;
    venueId: string;
    isAmend: string;
    validationErrors: string;
    statusText: string; //This node is not a part of the incoming object.
    filterStartDate: string; //This node is not a part of the incoming object
    filterEndDate: string; //This node is not a part of the incoming object,
    paymentRequestID: string;
    statusId: string;
    itemTypeId: string;
    approvalDate: string;
    statusValue: string;
    acceptDate: string;
    paymentId: string;
    isAccrual: boolean;
    invoiceId: string;
    queries: queryItem[];
    editAllowed: string;
    billDate: string;
    childRecordId: string;
    subTypeId: string;
    providerFasterPayFee: number;
    providerPayAmountLocal: number;
    addJobRequestID: string;
}

export interface eventUKUserGridDataSource {
    cards: eventUkUserGridRowItem[];
}

interface VirtualScrollingResponse {
    totalCount: number,
    data: any
}

/// Service
class SimpleGridService {

    // RemoteOperations Service 
    getInvoiceByIdGridRowsWithinRemoteOperations(params: string, id: string): Promise<AxiosResponse<VirtualScrollingResponse>> {
        return _get(REPORTS_GRID_API + `/${id}/V2` + params);
    }

    // RemoteOperations Service 
    getFutureShiftGridRowsWithinRemoteOperations(params: string, id: string): Promise<AxiosResponse<VirtualScrollingResponse>> {
        return _get(MANAGE_PAYMENT_CYCLES_API + `/${id}/FutureNegativeShifts/V2` + params);
    }

    // RemoteOperations Service with ProviderId
    getFutureShiftGridRowsWithinRemoteOperations_ByProvider(params: string, id: string, providerId:string): Promise<AxiosResponse<VirtualScrollingResponse>> {
        return _get(MANAGE_PAYMENT_CYCLES_API + `/${id}/FutureNegativeShifts/V2` + params + `&providerId=${providerId}`);
    }

    // RemoteOperations Service 
    getUnbilledGridRowsWithinRemoteOperations(params: string, id: string): Promise<AxiosResponse<VirtualScrollingResponse>> {
        return _get(REPORTS_GRID_API + `/${id}/unbilled/V2` + params);
    }
    // RemoteOperations Service 
    getShiftMatrixGridRowsWithinRemoteOperations(params: string): Promise<AxiosResponse<VirtualScrollingResponse>> {
        return _get(SIMPLE_DATA_GRID_REMOTE_OPERATIONS_API + params);
    }

    getFilteredShifts(dateFrom: string,dateTo: string, serviceId: string, venueId: string, providerId: string): Promise<AxiosResponse<any>> {
        return _get(MATCH_POP_UP_GRID_API + `?providerId=${providerId}&venueId=${venueId}&serviceId=${serviceId}&dateFrom=${dateFrom}&dateTo=${dateTo}`);
    }

    getSimpleGridRows(outStandingActions: string | null): Promise<AxiosResponse<any>> {
        return _get(SIMPLE_DATA_GRID_SERVICE_API + "?outStandingActionStatus=" + outStandingActions);
    }

    getSimpleGridRowsWithinDateRange(
        dateRangeFrom: string | undefined,
        dateRangeTo: string | undefined
    ): Promise<AxiosResponse<any>> {
        return _get(SIMPLE_DATA_GRID_SERVICE_API + "?dateRangeFrom=" + dateRangeFrom + "&dateRangeTo=" + dateRangeTo);
    }

    submitApproveShift(approveData: number[]): Promise<AxiosResponse<any>> {
        return _postArray(APPROVE_SHIFTS_API, approveData);
    }

    submitConfirmShift(approveData: number[]): Promise<AxiosResponse<any>> {
        return _postArray(CONFIRM_SHIFTS_API, approveData);
    }

    // align pay dates in future negative shifts of Payment screen
    submitApproveShiftWithPayDate(date: any, data: number[]): Promise<AxiosResponse<any>> {
        return _postArray(`${APPROVE_SHIFTS_API}?date=${date}`, data);
    }

    // need to set the return type to promise axios on final implementation
    submitDeleteShift(deleteData: string): Promise<AxiosResponse<any>> {
        return _delete(DELETE_SHIFTS_API + "?ids=" + deleteData);
    }
    //Manage shifts Grid for the Billable Items for a particular Invoice Id.
    getSimpleGridRowsbyInvoiceId(id: number): Promise<AxiosResponse<any>> {
        return _get(`${MANAGE_CLIENT_BILLING_CYCLES_API}/${id}`);
    }

    //Manage shifts Grid for the Billable Items for a particular Invoice Id.
    getSimpleGridReportbyInvoiceId(invoiceId: number): Promise<AxiosResponse<any>> {
        return _get(`${REPORTS_GRID_API}/${invoiceId}/unbilled`);
    }

    //Manage shifts Grid for the Billable Items for a particular Payment Cycle Id.
    getSimpleGridRowsbyPaymentCycleId(id: number): Promise<AxiosResponse<any>> {
        return _get(`${MANAGE_PAYMENT_CYCLES_API}/${id}`);
    }
    //Manage shifts Grid for the Billable Items for a particular Payment Id.
    getSimpleGridRowsbyPaymentId(id: string): Promise<AxiosResponse<any>> {
        return _get(`${ARTIST_PAYMENT_API}/${id}`);
    }
    getSimpleGridRowsbyBillableItemId(id: number): Promise<AxiosResponse<any>> {
        return _get(`${MANAGE_PAYMENT_CYCLES_API}/${id}`);
    }
    getFutureNegetiveShifts(paymentCycleId: number): Promise<AxiosResponse<any>> {
        return _get(`${MANAGE_PAYMENT_CYCLES_API}/${paymentCycleId}/FutureNegativeShifts`);
    }
    getManageShiftGridByBillableId(id: string): Promise<AxiosResponse<any>> {
        return _get(`${MANAGE_SHIFTS_API}?id=${id}`);
    }
    getManageShiftGrid(
        clientId: string,
        venueId: string,
        shiftStart: string,
        shiftEnd: string
    ): Promise<AxiosResponse<any>> {
        return _get(
            `${MANAGE_SHIFTS_API}?clientId=${clientId}&venueId=${venueId}&shiftStart=${shiftStart}&shiftFinish=${shiftEnd}`
        );
    }
    getManageShiftGridByPeriodNumber(
        clientId: string,
        venueId: string,
        serviceTypeId: string,
        periodNumber: string
    ): Promise<AxiosResponse<any>> {
        return _get(
            `${MANAGE_SHIFTS_API}?clientId=${clientId}&venueId=${venueId}&serviceTypeId=${serviceTypeId}&periodNumber=${periodNumber}`
        );
    }
    getManageShiftGridByPeriodNumberAndWeek(
        clientId: string,
        venueId: string,
        serviceTypeId: string,
        periodNumber: string,
        periodWeek: string
    ): Promise<AxiosResponse<any>> {
        return _get(
            `${MANAGE_SHIFTS_API}?clientId=${clientId}&venueId=${venueId}&serviceTypeId=${serviceTypeId}&periodNumber=${periodNumber}&periodWeek=${periodWeek}`
        );
    }
    getManageShiftGridForPreview(
        clientId: string,
        serviceTypeIds: [],
        dateRangeFrom: string,
        dateRangeTo: string,
        includeAccruals: boolean,
        groupAccruals: boolean,
    ): Promise<AxiosResponse<any>> {
        return _get(
            `${BILLING_CYCLES_PREVIEW_API}?clientId=${clientId}&serviceTypeIds=${serviceTypeIds}&dateRangeFrom=${dateRangeFrom}&dateRangeTo=${dateRangeTo}&includeAccruals=${includeAccruals}&groupAccruals=${groupAccruals}`
        );
    }
    getMyGigList(): Promise<AxiosResponse<any>> {
        return _get(`${GIG_LIST_API}`);
    }
    /**
     * Exposing function from BaseApiService only so that the calling component does not need to include both this service and the base service.
     * The idea here is that removing appInsights or adding this function to existing components can be done with little disruption.
     *
     * @param message {string}
     */
    traceAsErrorToAppInsights(message: string): boolean {
        return _aiTraceAsError(message);
    }
}

export default SimpleGridService;
