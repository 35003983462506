import React from "react";
import sharedUtils from "../grid/sharedUtilities";
import { AxiosResponse } from "axios";
import ManageDocumentsGridService from "../../services/ManageDocumentsGridService";
import { NullableDate } from "../grid/AddEditPopUpUtilities";
import paymentFormUtils, { ActionType, PaymentFormData } from "../Payments/PaymentFormUtils";
import PaymentService, { paymentCycleStatus, paymentStatus } from "../../services/PaymentService";
import { DateBox, LoadPanel } from "devextreme-react";
import PaymentBACSGrid from "../Payments/BACS/BacsGrid";
import { DateBoxOnValueChangedEvent } from "../../types/DevExtremeTypes";

interface PaymentSentProps {
    paymentCycleId: number;
    paymentData: PaymentFormData;
    onPaymentProduced: (status: string, statusId: string, isRestartDisable: boolean) => void;
    statusId: string;
    invoiceSentDate: NullableDate;
    syncDateFields: (date: NullableDate, type: string) => void;
    calculationsObject: any;
    location: any;
    history: any;
}

interface PaymentSentState {
    loadPanelVisible: boolean;
    disableProduceButton: boolean;
    disableSentButton: boolean;
    statusIdOnClick: string;
    downloadLink: string;
    downloadLinkCss: string;
    paymentFormData: PaymentFormData;
    errorMessage: [];
    gridRefreshSignal: boolean;
}

class PaymentSent extends React.Component<PaymentSentProps> {
    state: PaymentSentState;
    PaymentService: PaymentService;
    paymentFormUtils: paymentFormUtils;
    sharedUtils: sharedUtils;
    documentService: ManageDocumentsGridService;

    constructor(props: PaymentSentProps) {
        super(props);
        this.PaymentService = new PaymentService();
        this.paymentFormUtils = new paymentFormUtils();
        this.sharedUtils = new sharedUtils();
        this.state = {
            loadPanelVisible: false,
            disableProduceButton: false,
            disableSentButton: false,
            statusIdOnClick: "",
            downloadLink: "",
            downloadLinkCss: "icon-btn",
            paymentFormData: this.props.paymentData,
            errorMessage: [],
            gridRefreshSignal: false,
        };
        this.documentService = new ManageDocumentsGridService();
    }

    //Function which is invoked when the "Produce" as well as "Sent" button is clicked.
    onPaymentSent = (statusId: string, status: string) => {
        let data: PaymentFormData = this.paymentFormUtils.paymentDataInitializePaymentItems(
            this.props,
            statusId,
            status,
            this.state.paymentFormData.paySentDate
        );
        this.setState({
            gridData: data,
            loadPanelVisible: true,
            statusIdOnClick: statusId,
        });
        this.onSubmit(data);
    };

    onSubmit = (paymentData: PaymentFormData) => {
        var paymentCycleSubmitRequest = this.paymentFormUtils.convertFormDataToPaymentCycleRequest(
            paymentData,
            ActionType.Save,
            "",
            ""
        );
        this.PaymentService.postPaymentCycleDataV2(paymentCycleSubmitRequest)
            .then(this.handleSuccess)
            .catch(this.handleFailure);
    };

    handleSuccess = (response: AxiosResponse<any>) => {
        const { statusIdOnClick } = this.state;
        this.setState(
            (prevState: PaymentSentState) => ({
                loadPanelVisible: false,
                disableProduceButton: true,
                disableSentButton: statusIdOnClick == paymentStatus.PaymentPaid ? true : false,
                downloadLinkCss: "icon-btn",
                errorMessage: [],
            }),
            () => this.props.onPaymentProduced(paymentStatus.PaymentPaid, statusIdOnClick, false)
        );
    };

    handleFailure = (error: any) => {
        var { statusIdOnClick } = this.state;
        var respMessage: string = "postPaymentCycleInvoice failed with response: " + JSON.stringify(error);

        if (!this.PaymentService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
        this.setState({
            errorMessage: error.response !== null ? JSON.parse(JSON.stringify(error.response.data.error)) : null,
            loadPanelVisible: false,
            disableProduceButton: false,
            downloadLinkCss:
                statusIdOnClick == paymentCycleStatus.PaymentPackRequested ? "disabled icon-btn" : "icon-btn",
        });
    };

    //Inside the update function, when the Sent date is changed in the form, it would reflect here and vice versa.
    componentDidUpdate = (prevProps: PaymentSentProps) => {
    };

    //Trigger the onChange function for "Invoice Sent Date" field which is to be synchronised with the form.
    handleChangeSentDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            paymentFormData: {
                ...this.state.paymentFormData,
                paySentDate: dxValueChange.value,
            },
        });
    };

    render() {
        var viewDisabled = this.props.location.state?.viewDisabled;
        var { statusId } = this.props;
        var { disableSentButton } = this.state;
        var disablePaymentSentButton: boolean = 
        statusId === paymentStatus.SendToAccounts || statusId === paymentStatus.NotSendToAccounts;
        var sentButtonCssClass: string = "btn disabledCycleButtonColor btn--large";
        var sentButtonDisabled: boolean = true;
        if (disablePaymentSentButton && disableSentButton == false) {
            sentButtonDisabled = false;
            sentButtonCssClass = "btn saveCycleButtonColor btn--large";
        }
        let BACGridHeading = "";
        if(this.state.paymentFormData.paymentCycleTypeId == "10"){
            BACGridHeading = "BACS File";
        }
        else if(this.state.paymentFormData.paymentCycleTypeId == "20"){
            BACGridHeading = "BULK Faster Pay File";
        }
        else if(this.state.paymentFormData.paymentCycleTypeId == "30"){
            BACGridHeading = "BACS (Metro) File";
        }
        else{
            BACGridHeading = "";
        }
        return (
            <div className="card my-3">
                <div className="card-body">
                    <h4>
                        <b>Send Payment</b>
                    </h4>
                    <>
                        <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                        <br></br>
                        <h5>{BACGridHeading}</h5>
                        <PaymentBACSGrid
                            location={this.props.location}
                            history={this.props.history}
                            refreshSignal={this.state.gridRefreshSignal}
                            gridType={this.state.paymentFormData.paymentCycleTypeId}
                            id={this.props.paymentCycleId.toString()}
                        />
                        {this.state.errorMessage ? (
                            <span className="unscheduled-shift">
                                <ul>
                                    {this.state.errorMessage.map((item: any, uniqueKey: number) => {
                                        return (
                                            <li key={uniqueKey}>
                                                {item.columnName}: {item.errorMessage}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </span>
                        ) : (
                            <></>
                        )}
                        <div className="row align-items-center">
                            <div className="col-2">
                                <button
                                    className={(viewDisabled ? "disabled " : "") + sentButtonCssClass}
                                    type="button"
                                    onClick={(e: React.MouseEvent) => {
                                        e.preventDefault();
                                        this.onPaymentSent(paymentStatus.PaymentPaid, paymentCycleStatus.PaymentPaid);
                                    }}
                                    disabled={viewDisabled ? true : sentButtonDisabled}
                                >
                                    SENT
                                </button>
                            </div>
                            <div className="col-2 text-right">Sent Date</div>
                            <div className="col-4">
                                <DateBox
                                    displayFormat="dd/MM/yyyy"
                                    onValueChanged={this.handleChangeSentDate}
                                    value={
                                        this.state.paymentFormData.paySentDate
                                            ? this.state.paymentFormData.paySentDate
                                            : this.props.paymentData.paySentDate
                                            ? this.props.paymentData.paySentDate
                                            : undefined
                                    }
                                    useMaskBehavior={true}
                                    disabled={viewDisabled}
                                />
                            </div>
                        </div>
                    </>
                </div>
            </div>
        );
    }
}
export default PaymentSent;
