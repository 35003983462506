import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-regular-svg-icons";
import ManageDocumentsGridService from "../../services/ManageDocumentsGridService";
import { AxiosResponse } from "axios";
import { LoadPanel,Button } from "devextreme-react";
import sharedUtils from "../grid/sharedUtilities";
import { faTh,faRotateExclamation } from "@fortawesome/pro-light-svg-icons";
import billingFormUtils,{documentObject} from "../Billing/BillingFormUtils";
import notify from 'devextreme/ui/notify';
import { alert,custom } from "devextreme/ui/dialog";

interface ClientGrossActionCellProps {
    data: any;
    onRefresh?: (flag:boolean) => void;
}

interface ClientGrossActionCellState {
    filetype: string;
    loadPanelVisible: boolean;
    downloadLink: string;
}

class ClientGrossActionCell extends React.Component<ClientGrossActionCellProps> {
    state: ClientGrossActionCellState;
    service: ManageDocumentsGridService;
    sharedUtils: sharedUtils;
    billingFormUtils:billingFormUtils;
    constructor(props: ClientGrossActionCellProps) {
        super(props);
        this.state = {
            filetype: this.props.data.data.formatType,
            loadPanelVisible: false,
            downloadLink: ""
        };
        this.service = new ManageDocumentsGridService();
        this.sharedUtils = new sharedUtils();
        this.billingFormUtils = new billingFormUtils();
    }

    onDownloadClick = (link : string , id : string,event:any) => {
        event.event?.stopPropagation();
        this.setState({
            loadPanelVisible: true,
            downloadLink: link
        });
        this.service
            .getDocumentById(id)
            .then(this.handleDownloadSuccess)
            .catch(this.handleDownloadError);
    };

    handleDownloadSuccess = (response: AxiosResponse<any>) => {
        let link = this.state.downloadLink.split("/");
        let filename = link[link.length - 1];
        var FileSaver = require("file-saver");
        FileSaver.saveAs(response.data, filename);
        this.setState({
            loadPanelVisible: false,
        });
    };

    handleDownloadError = (error: any) => {
        this.setState({
            loadPanelVisible: false,
        });
        var respMessage: string = "getDocumentById failed with response: " + JSON.stringify(error);

        if (!this.service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };

    renderInvoiceDownloadButton = () => {
        if (this.props.data.data.documents) {
            if (this.props.data.data.documents.length > 0) {
                if (
                    this.props.data.data.documents[0].link &&
                    this.props.data.data.documents[0].documentType == "Invoice"
                ) {
                    return (
                        <Button className="btn icon-btn btn-file-downloader" onClick={(e) => this.onDownloadClick(this.props.data.data.documents[0].link , this.props.data.data.documents[0].id,e)}>
                            <FontAwesomeIcon icon={faDownload} />
                        </Button>
                    );
                }
            }
        }
    }

    renderBreakdownButton() {
        if (this.props.data.data.documents) {
            if (this.props.data.data.documents.length > 0) {
                if (
                    this.props.data.data.documents[1].link &&
                    this.props.data.data.documents[1].documentType == "InvoiceBreakdown"
                ) {
                    return (
                        <Button className="btn icon-btn btn-file-downloader" onClick={(e) => this.onDownloadClick(this.props.data.data.documents[1].link , this.props.data.data.documents[1].id,e)}>
                        <FontAwesomeIcon icon={faTh} />
                      </Button>
                    );
                }
            }
        }
    }

    renderInvoiceRegenerateButton = () => {
        if (this.props.data.data.documents) {
            if (this.props.data.data.documents.length > 0) {
                if (
                    this.props.data.data.documents[0].link &&
                    this.props.data.data.documents[0].documentType == "Invoice"
                ) {
                    return (
                        <Button className="btn icon-btn btn-file-downloader" onClick={(e) => this.onRegenerateClick(e)} >
                        <FontAwesomeIcon icon={faRotateExclamation} 
                        // title="re-generate document"
                        />
                      </Button>
                    );
                }
            }
        }
    }

    onRegenerateClick = (event:any) => {
        event.event?.stopPropagation();
        let invoiceId = this.props.data.data.id || '';
        this.setState({
            loadPanelVisible: true
        },()=>{
            this.service
            .regenerateDocument(invoiceId)
            .then(this.handleSuccess)
            .catch(this.handleError);
        })
    };

    handleSuccess = (response: AxiosResponse<any>) => {
            //setTimeout(()=>{
                this.setState({
                    loadPanelVisible: false
                },()=>{
                alert("The request to re-create the document has been processed successfully. Please wait approximately 20 seconds and click the Refresh button to see the new document.", "Success");
                //this.showHideCustomAlert();
                this.props.onRefresh ? this.props.onRefresh(true):null;
                });
            //},15000)
    };

    handleError = (error: any) => {
        this.setState({
            loadPanelVisible: false,
        });
        var respMessage: string = "generateDocument failed with response: " + JSON.stringify(error);

        if (!this.service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
        notify({ message: "The request to re-create the document has been unsuccessful. Please re-try", width: 300, shading: true,position: "center" }, 'error', 1000);
    };

    showHideCustomAlert = ()=> {
        let customAlert = custom({
            title: 'Success', 
            messageHtml: `<div class='text-center unscheduled-shift-success'> 
                         <div>The request to re-create the document has been processed successfully.</div>
                         <div style='padding-top=5px;'>Please wait approximately 20 seconds and click the Refresh button to see the new document.</div>
                         </div>`,
            buttons: [{
                text: "OK",
                onClick: (e) => {
                    //return { buttonText: e.component.option("text") }
                    customAlert.hide();
                }
            }]
        });
    
        customAlert.show();
    }

    render() {
        return (
            <div className="d-flex justify-content-between align-items-center">
                <div style={{minWidth:'70px',marginRight:'10%',width:'45%'}}>{this.sharedUtils.thousandsSeparator(this.props.data.data.total)}</div>
                <div style={{minWidth:'50px',width:'15%'}}>{this.renderInvoiceDownloadButton()}</div>
                <div style={{minWidth:'50px',width:'15%'}}>{this.renderInvoiceRegenerateButton()}</div>
                <div style={{minWidth:'50px',width:'15%'}}>{this.renderBreakdownButton()}</div>
                <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
            </div>
        );
    }
}

export default ClientGrossActionCell;
