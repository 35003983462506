import React, { MouseEvent } from "react";
import { AxiosResponse } from "axios";
import { withTranslation, WithTranslation } from "react-i18next";
import {
    DateBoxOnValueChangedEvent,
    LookUpOnValueChangedEvent,
    NumberBoxOnValueChangedEvent,
    SelectBoxOnValueChangedEvent,
    TextBoxOnValueChangedEvent,
} from "../../types/DevExtremeTypes";
import LookupService, { LookupTypeIndexes } from "../../services/LookupService";
import DropDownFromLookupTable from "../select/SelectBoxFromLookupTable";
import DropDownFromLookupTableDynamic from "../select/SelectBoxFromLookupTableDynamic";
import LookupTypeListDynamic from "../select/LookupTypeListDynamic";
import PaymentRequestUtils, { PaymentRequestFormData, PaymentRequestMatchedPopUpFormData } from "./PaymentRequestUtils";
import PaymentService, { AddEditPaymentReqSubmitRequest, CalculationResponse } from "../../services/PaymentService";
import sharedUtils from "../grid/sharedUtilities";
import AddEditPopUpService, { RaiseQueryFormData } from "../../services/AddEditPopUpService";
import { AddEditFormData, NullableDate } from "../grid/AddEditPopUpUtilities";
import addEditPopupUtils from "../grid/AddEditPopUpUtilities";
import { DateBox, LoadPanel, NumberBox, Popup, ScrollView, TextArea, TextBox, LoadIndicator, SelectBox } from "devextreme-react";
import { PaymentTypes } from "../../services/FindAnArtistService";
import { queryTypes } from "../../services/QueryService";
import MatchedCard from "./MatchedCard";
import outstandingActionsUtilities, { minuteLookup} from "../../components/Outstanding-Actions/OutstandingActionsUtilities";
import { ServiceType, SubServiceType } from "../../common/ServiceUtilities";

interface MatchedPopUpProps extends WithTranslation {
    location: any;
    history: any;
    setPayReqItemObj: (payReqItemObj: PaymentRequestMatchedPopUpFormData) => void;
    payReqId: string;
    captureEditButtonState: (isEdit: boolean) => void;
}
// State
interface MatchedPopUpState {
    editViewDataItem: PaymentRequestMatchedPopUpFormData;
    paymentViewDataItem: PaymentRequestFormData;
    billableDataItem: AddEditFormData;
    showLoadIndicator: boolean;
    errorMessage: [];
    IsShowSuccess: boolean;
    IsShowError: boolean;
    validationMessage: string;
    isViewMode: boolean;
    configuarationValue: [];
    configurationIdIndex: string[];
    configuarationValueIndex: string[];
    queryItem: RaiseQueryFormData;
    loadPanelVisible: boolean;
    isDisableMatchButton: boolean;
    venueLabel: string;
    venueClassName: string;
    providerLabel: string;
    providerClassName: string;
    dateLabel: string;
    dateClassName: string;
    feesLabel: string;
    feesClassName: string;
    isMatchingShiftQueryShow: boolean;
    providerLabelForConf: string;
    providerClassNameForConf: string;
    feeLabelForConf: string;
    feeClassNameForConf: string;
    artistFeeLabel: string;
    feeToVenueClass: string;
    bookingFeeAmount: number;
    valueChangeTimer: any;
    countOfDays: number;
    showSavePopUp: boolean;
    showCancelPopUp: boolean;
    showRaiseQueryPopUp: boolean;
    rateChangeTimer: any;
    loadIndicatorVisible: boolean;
    clientBusinessEntityId: string;
    venueId: string;
    artistFee: string;
    date: NullableDate;
    startTime: NullableDate;
    finishTime: NullableDate;
    serviceSubTypeId: string;
    typeId: string;
    description: string;
    extras: string;
    isEditClicked: boolean;
    isArtistPayChange: boolean;
    showDuplicateItem: boolean;
    DuplicateResponse: any;
    hours: number;
    minutes: string;
}

class MatchedPopUp extends React.Component<MatchedPopUpProps> {
    utils: PaymentRequestUtils;
    sharedUtils: sharedUtils;
    state: MatchedPopUpState;
    paymentService: PaymentService;
    billableItemUtils: addEditPopupUtils;
    editViewService: AddEditPopUpService;
    lookupService: LookupService;
    outStandingActionUtils: outstandingActionsUtilities;

    constructor(props: MatchedPopUpProps) {
        super(props);
        this.utils = new PaymentRequestUtils();
        this.outStandingActionUtils = new outstandingActionsUtilities();
        this.sharedUtils = new sharedUtils();
        this.paymentService = new PaymentService();
        this.billableItemUtils = new addEditPopupUtils();
        this.editViewService = new AddEditPopUpService();
        var convertedEditViewDataItem = this.utils.initializePaymentRequestMatchedPopUpItems();
        var convertedBillableViewItem = this.billableItemUtils.initializeBillableItem("");
        var convertedQueryFormData = this.billableItemUtils.initializeQueryFormData(
            "",
            "",
            "",
            "Payment Request Query",
            ""
        );
        let convertedPaymentViewDataItem = this.utils.initializeAddEditPaymentItems(
            props.location.state && props.location.state.id ? props.location.state.id.toString() : ""
        );
        this.lookupService = new LookupService();
        this.state = {
            loadPanelVisible: false,
            configuarationValue: [],
            configuarationValueIndex: [],
            configurationIdIndex: [],
            editViewDataItem: convertedEditViewDataItem,
            billableDataItem: convertedBillableViewItem,
            queryItem: convertedQueryFormData,
            showLoadIndicator: false,
            errorMessage: [],
            IsShowSuccess: false,
            IsShowError: false,
            validationMessage: "",
            isDisableMatchButton: true,
            isViewMode: true,
            venueLabel: "",
            providerLabel: "",
            dateLabel: "",
            feesLabel: "",
            isMatchingShiftQueryShow: false,
            venueClassName: "",
            providerClassName: "",
            dateClassName: "",
            feesClassName: "",
            providerLabelForConf: "",
            providerClassNameForConf: "",
            feeLabelForConf: "",
            feeClassNameForConf: "",
            artistFeeLabel: "",
            feeToVenueClass: "",
            bookingFeeAmount: 0,
            valueChangeTimer: null,
            countOfDays: 0,
            paymentViewDataItem: convertedPaymentViewDataItem,
            showSavePopUp: false,
            showCancelPopUp: false,
            showRaiseQueryPopUp: false,
            rateChangeTimer: null,
            loadIndicatorVisible: false,
            clientBusinessEntityId: "",
            venueId: "",
            artistFee: "",
            date: undefined,
            startTime: undefined,
            finishTime: undefined,
            serviceSubTypeId: "",
            typeId: "",
            description: "",
            extras: "",
            isEditClicked: false,
            isArtistPayChange: false,
            showDuplicateItem: false,
            DuplicateResponse: [],
            hours: 0,
            minutes: "00",
        };
    }
    /**
     * On component load, retrieve financial chart data points and service types for filtering
     */
    componentDidMount() {
        this.lookupService
            .getLookupByLookupTypeIndex(LookupTypeIndexes.configuration)
            .then(this.handleConfigurationLookupSuccess)
            .catch(this.handleError);
        this.getPaymentRequestFormDataById();
    }



    handleConfigurationLookupSuccess = (response: AxiosResponse<any>) => {
        var configData = this.sharedUtils.getConfigurationDataFromLookupResponse(response.data.data);
        this.setState({
            configuarationValue: response.data.data,
            configurationIdIndex: configData.id,
            configuarationValueIndex: configData.value,
        });
    };

    getPaymentRequestFormDataById = () => {
        let paymentRequestId: string = "";
        if (this.props.payReqId) {
            paymentRequestId = this.props.payReqId;
        }
        else if (this.props.location && this.props.location.state && this.props.location.state.id) {
            paymentRequestId = this.props.location.state.id;
        }
        if (paymentRequestId) {
            this.paymentService.getPaymentRequestFormData(paymentRequestId)
                .then(this.handleSuccess)
                .catch(this.handleError)
        }
    }

    handleSuccess = (response: AxiosResponse<any>) => {
        var convertedFormData = this.utils.convertMatchedPopUpResponseToFormData(response.data.data);
        this.setState({
            editViewDataItem: convertedFormData
        }, () => { this.props.setPayReqItemObj(convertedFormData) });
        let serviceSubType: string = "";
        let paymentRequestTypeId: string = "";
        let startTime: NullableDate = undefined;
        let finishTime: NullableDate = undefined;
        let paymentRequestStatusId: string = "";
        let quantityOrHours: string = "";
        if (response && response.data && response.data.data.serviceSubType && response.data.data.serviceSubType.id) {
            serviceSubType = response.data.data.serviceSubType.id.toString();
        }
        if (response && response.data && response.data.data.paymentRequestType && response.data.data.paymentRequestType.id) {
            paymentRequestTypeId = response.data.data.paymentRequestType.id.toString();
        }
        if (response && response.data && response.data.data.startTime) {
            startTime = this.sharedUtils.convertStringToTime(response.data.data.startTime);
        }
        if (response && response.data && response.data.data.finishTime) {
            finishTime = this.sharedUtils.convertStringToTime(response.data.data.finishTime);
        }
        if (response && response.data && response.data.data.paymentRequestStatusLookUp && response.data.data.paymentRequestStatusLookUp.id) {
            paymentRequestStatusId = response.data.data.paymentRequestStatusLookUp.id.toString();
        }

        if (response && response.data && response.data.data.quantityOrHours !== null && response.data.data.quantityOrHours !== "") {
            quantityOrHours = response.data.data.quantityOrHours.toString();

            // converting back quantity into hours and min for weekly cleaning only
            if (serviceSubType == SubServiceType.WeeklyCleaning) {
                let quantitySubFields: string[] = this.outStandingActionUtils.splitQuantityIntoHoursMinutesFormat(response.data.data.quantityOrHours);               
                this.setState({
                    hours:
                        quantitySubFields.length > 0 && quantitySubFields[0]
                            ? this.sharedUtils.convertStringToInt(quantitySubFields[0])
                            : 0,
                    minutes: quantitySubFields.length > 0 && quantitySubFields[1] ? quantitySubFields[1] : "00",
                })
            }
        }

        if (response && response.data) {
            this.setState({
                paymentViewDataItem: {
                    ...this.state.paymentViewDataItem,
                    clientBusinessEntityId: response.data.data.clientBusinessEntity.id.toString(),
                    providerBusinessEntityId: response.data.data.providerBusinessEntity.id.toString(),
                    venueId: response.data.data.venue.id.toString(),
                    serviceTypeId: response.data.data.serviceType.id.toString(),
                    serviceSubTypeId: serviceSubType,
                    typeLookUpId: response.data.data.typeLookUp?.id.toString(),
                    startTime: startTime,
                    finishTime: finishTime,
                    description: response.data.data.description,
                    otherDescription:  response.data.data.otherDescription,
                    dateFrom: this.sharedUtils.convertStringToDate(response.data.data.dateFrom),
                    dateTo: this.sharedUtils.convertStringToDate(response.data.data.dateTo),
                    providerPayAmountLocal: this.sharedUtils.convertStringToFloat(response.data.data.providerPayAmountLocal),
                    providerFasterPayFee: this.sharedUtils.convertStringToFloat(response.data.data.providerFasterPayFee),
                    providerPayAmountLocalWOExtras: this.sharedUtils.convertStringToFloat(response.data.data.providerPayAmountLocalWOExtras),
                    providerFasterPayOriginalAmount: this.sharedUtils.convertStringToFloat(response.data.data.providerFasterPayOriginalAmount),
                    comments: response.data.data.comments,
                    internalComments: response.data.data.internalComments,
                    requestDate: response.data.data.requestDate,
                    status: response.data.data.paymentRequestStatusLookUp.value,
                    id: response.data.data.id,
                    fasterPayment: this.sharedUtils.convertBooleanToString(response.data.data.fasterPayment),
                    paymentRequestTypeID: paymentRequestTypeId,
                    paymentRequestStatusID: paymentRequestStatusId,
                    providerPayAmountLocalExtras: this.sharedUtils.convertStringToFloat(response.data.data.providerPayAmountLocalExtras),
                    providerPayAmountLocalExtrasDescription: response.data.data.providerPayAmountLocalExtrasDescription,
                    quantityOrHours: quantityOrHours,
                    rate: this.sharedUtils.convertStringToFloat(response.data.data.rate),
                },
                clientBusinessEntityId: response.data.data.clientBusinessEntity.id.toString(),
                venueId: response.data.data.venue.id.toString(),
                artistFee: this.sharedUtils.convertStringToFloat(response.data.data.providerPayAmountLocal),
                date: this.sharedUtils.convertStringToDate(response.data.data.dateFrom),
                startTime: startTime,
                finishTime: finishTime,
                serviceSubTypeId: serviceSubType,
                typeId: response.data.data.typeLookUp?.id.toString(),
                description: response.data.data.description,
                extras: this.sharedUtils.convertStringToFloat(response.data.data.providerPayAmountLocalExtras)
            })
        }
        this.getRecentProviderWorker();
    }

    hideDuplicateItem = () => {
        this.setState({
            showDuplicateItem: false
        });
    };

    getRecentProviderWorker = () => {
        var date = new Date();
        date.setDate(date.getDate() - 42);
        this.paymentService.getRecentProviderWorker(this.state.editViewDataItem.venue.id, this.state.editViewDataItem.serviceType.id, this.sharedUtils.convertDateToString(date), this.state.editViewDataItem.providerBusinessEntity.id)
            .then(this.handleSuccessRecentProvider)
            .catch(this.handleError)
    }

    handleSuccessRecentProvider = (response: AxiosResponse<any>) => {
        this.setState({
            countOfDays: response.data.data.length
        });
    }

    handleError = (error: AxiosResponse<any>) => {
        var respMessage: string = "Matched Popup form load failed with response: " + JSON.stringify(error);

        if (!this.paymentService.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    }

    handleChangeComments = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                billableDataItem: {
                    ...this.state.billableDataItem,
                    comments: dxValueChange.value
                }
            });
            this.setState({
                queryItem: {
                    ...this.state.queryItem,
                    queryDescription: dxValueChange.value,
                },
            });

        }
    }

    handleChangeInternalComments = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                paymentViewDataItem: {
                    ...this.state.paymentViewDataItem,
                    internalComments: dxValueChange.value
                }
            })
        }
    }

    handleChangeQueryDescription = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            if (this.state.paymentViewDataItem.paymentRequestTypeID == PaymentTypes.AddJob) {
                this.setState({
                    queryItem: {
                        ...this.state.queryItem,
                        queryTypeLookupID: queryTypes.AddJob
                    }
                });
            }
            else {
                this.setState({
                    queryItem: {
                        ...this.state.queryItem,
                        queryTypeLookupID: queryTypes.PaymentRequest
                    }
                });
            }
            this.setState({
                queryItem: {
                    ...this.state.queryItem,
                    queryDescription: dxValueChange.value,
                    clientId: this.state.paymentViewDataItem.clientBusinessEntityId,
                    serviceTypeId: this.state.paymentViewDataItem.serviceTypeId,
                    venueId: this.state.paymentViewDataItem.venueId,
                    linkedId: this.state.paymentViewDataItem.id
                }
            });
        }
    }

    onSubmitQuery = () => {
        this.setState({
            showRaiseQueryPopUp: true
        });
    };

    onClickRaiseQuery = () => {
        let querySubmissionError: string = "";
        querySubmissionError = this.validationCheckForQuery();
        if (querySubmissionError.length > 0) {
            this.setState({
                validationMessage: querySubmissionError,
                loadPanelVisible: false
            });
        } else {
            this.setState({
                validationMessage: "",
                loadPanelVisible: true,
            });
            this.editViewService
                .uploadQueryFormData(this.state.queryItem)
                .then(this.handleQuerySubmissionSuccess)
                .catch(this.handleQuerySubmissionFailure);
        }
    }

    handleChangeProviderPayAmount = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                billableDataItem: {
                    ...this.state.billableDataItem,
                    provider_Pay_Amount_Local_Ccy: dxValueChange.value
                }
            })
            this.state.valueChangeTimer = setTimeout(this.startCallingFinancialCalculation, 1000);
        }

    }
    startCallingFinancialCalculation = () => {
        if (this.state.valueChangeTimer) {
            clearTimeout(this.state.valueChangeTimer);
            this.state.valueChangeTimer = null;
            var clientAmount: number[] = [];
            var providerAmount: number[] = [];
            var clientBillableTaxLocalCcy: number = 0;
            var clientBillableTotalLocalCcy: number = 0;
            var providerTaxLocalCcy: number = 0;
            var providerTotalLocalCcy: number = 0;
            if (this.state.venueLabel == "Matched" && this.state.dateLabel == "Matched") {
                this.setState({
                    billableDataItem: {
                        ...this.state.billableDataItem,
                        client_Billable_Amount_Local_Ccy:
                            this.state.billableDataItem.provider_Pay_Amount_Local_Ccy +
                            this.state.billableDataItem.fee_Amount_Local_Ccy,
                    },
                });
                clientAmount = this.billableItemUtils.clientTaxCal(this.state.billableDataItem, this.state.configuarationValueIndex);
                if (clientAmount.length > 0) {
                    clientBillableTaxLocalCcy = clientAmount[0];
                    clientBillableTotalLocalCcy = clientAmount[1];
                }
                this.setState({
                    billableDataItem: {
                        ...this.state.billableDataItem,
                        client_Billable_Tax_Local_Ccy: clientBillableTaxLocalCcy,
                    },
                });
                this.setState({
                    billableDataItem: {
                        ...this.state.billableDataItem,
                        client_Billable_Total_Local_Ccy: clientBillableTotalLocalCcy,
                    },
                });
                providerAmount = this.billableItemUtils.providerPayTaxCal(
                    this.state.billableDataItem,
                    this.state.configuarationValueIndex
                );
                if (providerAmount.length > 0) {
                    providerTaxLocalCcy = providerAmount[0];
                    providerTotalLocalCcy = providerAmount[1];
                }

                this.setState({
                    billableDataItem: {
                        ...this.state.billableDataItem,
                        provider_Pay_Tax_Local_Ccy: providerTaxLocalCcy,
                    },
                });

                this.setState({
                    billableDataItem: {
                        ...this.state.billableDataItem,
                        provider_Pay_Total_Local_Ccy: providerTotalLocalCcy,
                    },
                    isDisableMatchButton: false

                });
            }
        }
    }

    handleFormDataSubmissionSuccess = (navigateFrom: string) => {
        if (navigateFrom == "cancel") {
            this.props.history.goBack();
        }
        else {
            this.onSaveClose();
            this.getPaymentRequestFormDataById();
            // window.location.reload();
        }
        this.setState({
            isEditClicked: false,
            isViewMode: true,
        }, () => { this.props.captureEditButtonState(this.state.isEditClicked) })
    };

    failureMessageVisible = () => {
        this.setState({
            IsShowError: true,
        });
    };

    hideLoadpanel = () => {
        this.setState({ loadPanelVisible: false });
    };

    onCancel = () => {
        this.props.history.push({
            pathname: "/paymentRequests",
            state: {
                pointOfNavigation: "popUp"
            },
        });
    }

    validationCheckForQuery = () => {
        let errorMessage: string = "";
        let commentsField: string = this.state.queryItem.queryDescription;
        if (commentsField.length === 0) {
            errorMessage = "Please Enter the Query Description in the Internal comments.";
        }
        return errorMessage;
    };


    handleQuerySubmissionSuccess = () => {
        this.props.history.goBack();
        this.onRaiseQueryClose();
    };
    handleQuerySubmissionFailure = (error: any) => {
        this.hideLoadpanel();
    };

    venueNameExtraction = (venue: string) => {
        var billableItemVenue: string = "";
        var parts = venue.split("-");
        billableItemVenue = parts[1];
        return billableItemVenue;
    }
    showMatchOrNotMatch = (billableItemObj: AddEditFormData) => {
        this.setState({
            billableDataItem: billableItemObj
        });
        var billableItemVenue = this.venueNameExtraction(billableItemObj.venue);
        if (this.state.editViewDataItem.venue.venueName == billableItemVenue.trim() &&
            this.sharedUtils.convertDateToString(this.state.editViewDataItem.dateFrom) == this.sharedUtils.convertDateToString(billableItemObj.date) &&
            this.state.editViewDataItem.providerBusinessEntity.shortName == billableItemObj.provider &&
            this.state.editViewDataItem.providerPayAmountLocal == billableItemObj.provider_Pay_Amount_Local_Ccy) {
            this.setState({
                isDisableMatchButton: false
            });
        }
        if (this.state.editViewDataItem.venue.venueName == billableItemVenue.trim()) {
            this.setState({
                venueLabel: "Matched",
                venueClassName: "tile bg-success"
            });
        }
        else {
            this.setState({
                venueLabel: "Not Matched",
                venueClassName: "tile bg-danger",
                isDisableMatchButton: true
            });
        }

        if (this.sharedUtils.convertDateToString(this.state.editViewDataItem.dateFrom) == this.sharedUtils.convertDateToString(billableItemObj.date)) {
            this.setState({
                dateLabel: "Matched",
                dateClassName: "tile bg-success"
            });
        }
        else {
            this.setState({
                dateLabel: "Not Matched",
                dateClassName: "tile bg-danger",
                isDisableMatchButton: true
            });
        }
        if (this.state.editViewDataItem.providerBusinessEntity.shortName == billableItemObj.provider) {
            this.setState({
                providerLabel: "Matched",
                providerClassName: "tile bg-success",
                providerLabelForConf: "Provider",
                providerClassNameForConf: "tile bg-success"
            });
        }
        else {
            this.setState({
                providerLabel: "Not Matched",
                providerClassName: "tile bg-danger",
                providerLabelForConf: "Use Provider from Request?",
                providerClassNameForConf: "tile bg-warning",
                isDisableMatchButton: false
            });
        }
        if (this.state.editViewDataItem.providerPayAmountLocal == billableItemObj.provider_Pay_Amount_Local_Ccy) {
            this.setState({
                feesLabel: "Matched",
                feesClassName: "tile bg-success",
                feeLabelForConf: "Fee to Artist",
                feeClassNameForConf: "tile bg-success",
                artistFeeLabel: "Request",
                feeToVenueClass: "tile bg-cleaner",
                bookingFeeAmount: billableItemObj.provider_Pay_Amount_Local_Ccy

            });
        }
        else {
            this.setState({
                isDisableMatchButton: true,
                feesLabel: "Not Matched",
                feesClassName: "tile bg-danger",
                feeLabelForConf: "Enter Correct Fee to Artist?",
                feeClassNameForConf: "tile bg-warning",
                artistFeeLabel: "Artist",
                feeToVenueClass: "tile bg-warning",
                bookingFeeAmount: billableItemObj.provider_Pay_Amount_Local_Ccy,
                billableDataItem: {
                    ...this.state.billableDataItem,
                    provider_Pay_Amount_Local_Ccy: undefined,
                    client_Billable_Amount_Local_Ccy: undefined
                }
            });
        }
    }
    setBillableItemObj = (billableItemObj: AddEditFormData) => {
        this.showMatchOrNotMatch(billableItemObj);
        this.setState({
            isMatchingShiftQueryShow: true
        });
    }

    setMatchButtonState = (buttonState: boolean, loadPanelVisible: boolean) => {
        var isHideDetails: boolean = false;
        this.setState({
            isDisableMatchButton: isHideDetails ? true : buttonState,
            loadPanelVisible: isHideDetails ? false : loadPanelVisible,
            billableDataItem: {
                ...this.state.billableDataItem,
                internalComments: isHideDetails ? "" : this.state.billableDataItem.internalComments,
                comments: isHideDetails ? "" : this.state.billableDataItem.comments,
                provider_Pay_Amount_Local_Ccy: isHideDetails ? 0 : this.state.billableDataItem.provider_Pay_Amount_Local_Ccy

            }
        });
    }

    renderNotificationMessage = () => {

        if (this.state.isEditClicked) {
            return (
                <div className="row mx-0">
                    <div className="col-12 col-lg-12 row mx-0 pr-0 mt-3">
                        <span><i className="matchedPopUpText">Please Save or Cancel Edit to proceed further</i></span>
                    </div>
                </div>
            );
        }
        else if (this.state.countOfDays) {
            return (
                <div className="row mx-0">
                    <div className="col-12 col-lg-12 row mx-0 pr-0 mt-3">
                        <span><i className="matchedPopUpText">The artist has worked here previously {this.state.countOfDays} times - Please check if residency in place</i></span>
                    </div>
                </div>
            );
        }
        else {
            return <></>
        }

    }

    handleChangeClientTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                paymentViewDataItem: {
                    ...this.state.paymentViewDataItem,
                    clientBusinessEntityId: dxValueChange.value,
                },
            });
        }
    }

    handleChangeVenueTypeSelect = (dxValueChange: LookUpOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                paymentViewDataItem: {
                    ...this.state.paymentViewDataItem,
                    venueId: dxValueChange.value,
                },
            });
        }
    }

    handleChangeProviderLocalCurrancyAmount = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined && this.state.paymentViewDataItem.providerPayAmountLocal !== dxValueChange.value) {
            this.setState({
                paymentViewDataItem: {
                    ...this.state.paymentViewDataItem,
                    providerPayAmountLocal: dxValueChange.value,
                },
            });
        }
    }

    handleChangeFasterPayFee = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined && this.state.paymentViewDataItem.providerFasterPayFee !== dxValueChange.value) {
            this.setState({
                paymentViewDataItem: {
                    ...this.state.paymentViewDataItem,
                    providerFasterPayFee: dxValueChange.value,
                },
            });

            this.state.rateChangeTimer = setTimeout(this.startCallingFinancialAdjustment, 1000);
        }
    }

    handleChangefasterPayOriginalAmount = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                paymentViewDataItem: {
                    ...this.state.paymentViewDataItem,
                    providerFasterPayOriginalAmount: dxValueChange.value,
                },
                isArtistPayChange: true
            });

            this.state.rateChangeTimer = setTimeout(this.startCallingFinancialAdjustment, 1000);
        }
    }

    handleChangeDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                paymentViewDataItem: {
                    ...this.state.paymentViewDataItem,
                    dateFrom: dxValueChange.value,
                },
            });
        }
    }
    handleChangeDateTo = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                paymentViewDataItem: {
                    ...this.state.paymentViewDataItem,
                    dateTo: dxValueChange.value,
                },
            });
        }
    }

    handleChangeStartTime = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                paymentViewDataItem: {
                    ...this.state.paymentViewDataItem,
                    startTime: dxValueChange.value,
                },
            });
        }
    };

    handleChangeServiceSubTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                paymentViewDataItem: {
                    ...this.state.paymentViewDataItem,
                    serviceSubTypeId: dxValueChange.value,
                    typeLookUpId: "",
                    otherDescription: "",
                },
            });
        }
    }

    handleChangeTypeSelect = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                paymentViewDataItem: {
                    ...this.state.paymentViewDataItem,
                    typeLookUpId: dxValueChange.value,
                    otherDescription: ""
                },
            });
        }
    };

    handleChangeOtherDescription = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                paymentViewDataItem: {
                    ...this.state.paymentViewDataItem,
                    otherDescription: dxValueChange.value,
                },
            });
        }
    };

    handleChangeFinishTime = (dxValueChange: DateBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                paymentViewDataItem: {
                    ...this.state.paymentViewDataItem,
                    finishTime: dxValueChange.value,
                },
            });
        }
    };

    handleChangeQuantity = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                paymentViewDataItem: {
                    ...this.state.paymentViewDataItem,
                    quantityOrHours: dxValueChange.value,
                },
            },() =>{
                setTimeout(this.calculateProviderNet, 1000);
            });
        }
    }

    handleChangeRate = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                paymentViewDataItem: {
                    ...this.state.paymentViewDataItem,
                    rate: dxValueChange.value,
                },
            },() =>{
                setTimeout(this.calculateProviderNet, 1000);
            });
        }
    }

    handleChangeDescription = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                paymentViewDataItem: {
                    ...this.state.paymentViewDataItem,
                    description: dxValueChange.value,
                },
            });
        }
    }

    handleChangeExtrasDescription = (dxValueChange: TextBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                paymentViewDataItem: {
                    ...this.state.paymentViewDataItem,
                    providerPayAmountLocalExtrasDescription: dxValueChange.value,
                },
            });
        }
    }

    handleChangeExtraAmount = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                paymentViewDataItem: {
                    ...this.state.paymentViewDataItem,
                    providerPayAmountLocalExtras: dxValueChange.value,
                },
            });
        }
    }

    startCallingFinancialAdjustment = () => {
        if (this.state.rateChangeTimer) {
            clearTimeout(this.state.rateChangeTimer);
            this.state.rateChangeTimer = null;
            if (this.state.paymentViewDataItem.providerFasterPayOriginalAmount || this.state.paymentViewDataItem.providerFasterPayFee) {
                this.financialCalculations();
            }
        }
    };

    handleChangeHours = (dxValueChange: NumberBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                hours: dxValueChange.value,
            });
            setTimeout(this.reCalculateQuantity, 1000);
        }
    };

    handleChangeMinutes = (dxValueChange: SelectBoxOnValueChangedEvent) => {
        if (dxValueChange.event !== undefined) {
            this.setState({
                minutes: dxValueChange.value,
            });
            setTimeout(this.reCalculateQuantity, 1000);
        }
    };

    //Helper function to recalulate the Quantity when either of the minute or Hour field varies.
    reCalculateQuantity = () => {
        var delimeter = ".";
        var { hours, minutes } = this.state; //Destructure the state to pull out the contents.
        var convertedHours: string = this.sharedUtils.convertNumberToString(hours);
        var quantity: string = `${convertedHours}${delimeter}${minutes}`;
        this.setState({
            paymentViewDataItem: {
                ...this.state.paymentViewDataItem,
                quantityOrHours: this.sharedUtils.convertStringToFloat(quantity),
            },
        },() =>{
            setTimeout(this.calculateProviderNet, 1000);
        });
    };

    calculateProviderNet = () => {
        let rate:number = this.state.paymentViewDataItem.rate ? this.state.paymentViewDataItem.rate : 0;
        let quantity:number = this.state.paymentViewDataItem.quantityOrHours ? this.state.paymentViewDataItem.quantityOrHours : 1;
        let amount = rate * quantity;
        this.setState({
            paymentViewDataItem: {
                ...this.state.paymentViewDataItem,
                providerFasterPayOriginalAmount: amount,
            },
            isArtistPayChange: true
        }, () =>{
            this.state.rateChangeTimer = setTimeout(this.startCallingFinancialAdjustment, 1000);
        });

    }

    hideLoadIndicator = () => {
        this.setState({
            loadIndicatorVisible: false,
        });
    };

    showLoadIndicator = () => {
        this.setState({ loadIndicatorVisible: true });
    };

    financialCalculations = () => {
        this.showLoadIndicator();
        if (this.state.isArtistPayChange) {
            this.setState({
                paymentViewDataItem: {
                    ...this.state.paymentViewDataItem,
                    providerFasterPayFee: "",
                    providerPayAmountLocal: this.state.paymentViewDataItem.fasterPayment.toString() == "false" || this.state.paymentViewDataItem.fasterPayment.toString() == "No" ? this.state.paymentViewDataItem.providerFasterPayOriginalAmount : this.state.paymentViewDataItem.providerPayAmountLocal,
                }
            });
        }
        let calCulationObject = this.utils.convertPayCalcDataToCalcRequest(
            this.state.paymentViewDataItem,
            null,
            undefined,
            this.props.location && this.props.location.state ? this.props.location.state.id : this.state.paymentViewDataItem.id
        );

        this.editViewService
            .uploadPaymentFormData(calCulationObject)
            .then(this.handleCalculationSuccess)
            .catch((err) => {
                this.setState({
                    errorMessage: err.response ? JSON.parse(JSON.stringify(err.response.data.error)) : null, // For capturing response at the time of exception and showing error message
                    IsShowSuccess: false,
                });
                this.failureMessageVisible();
                this.hideLoadIndicator();
            })
            .finally(() => {
                this.hideLoadIndicator();
            });

    };

    handleCalculationSuccess = (response: AxiosResponse<any>) => {
        let serverResponse: CalculationResponse = response.data.data;
        this.hideLoadIndicator();

        this.setState({
            IsShowError: false,
            isArtistPayChange: false
        });
        // Convert server response to proper data types
        this.setState({
            paymentViewDataItem: {
                ...this.state.paymentViewDataItem,
                providerPayAmountLocal: this.sharedUtils.convertStringToFloat(serverResponse.providerPayAmountLocal),
                providerPayAmountLocalExtras: this.sharedUtils.convertStringToFloat(serverResponse.providerPayAmountLocalExtras),
                providerPayAmountLocalWOExtras: this.sharedUtils.convertStringToFloat(serverResponse.providerPayAmountLocalWOExtras),
                providerFasterPayFee: this.sharedUtils.convertStringToFloat(serverResponse.providerFasterPayFee),
                providerFasterPayOriginalAmount: this.sharedUtils.convertStringToFloat(serverResponse.providerFasterPayOriginalAmount),
            }
        })
    };

    onSaveClose = () => {
        this.setState({
            showSavePopUp: false
        });
    }

    onCancelClose = () => {
        this.setState({
            showCancelPopUp: false
        });
    }

    onRaiseQueryClose = () => {
        this.setState({
            showRaiseQueryPopUp: false
        });
    }

    postPaymentData = (navigateFrom: string) => {
        let paymentRequestSubmitRequest: AddEditPaymentReqSubmitRequest;
        paymentRequestSubmitRequest = this.utils.convertFormDataToPaymentRequest(this.state.paymentViewDataItem, navigateFrom);
        //passing object data to the server
        this.paymentService
        .postPaymentRequestFormData(paymentRequestSubmitRequest)
        .then(() => { this.handleFormDataSubmissionSuccess(navigateFrom) })
        .catch((err) => {
            this.setState({
                errorMessage:
                    err.response && err.response.data && err.response.data.error
                        ? JSON.parse(JSON.stringify(err.response.data.error))
                        : null,
                IsShowSuccess: false,
                isEditClicked: false,
                isViewMode: true,
            }, () => { this.props.captureEditButtonState(this.state.isEditClicked) })
            this.failureMessageVisible();
            this.hideLoadpanel();
        })
        .finally(() => {
            this.hideLoadpanel();
        });
    }


    onSubmit = (navigateFrom: string) => {
        this.setState({
            loadPanelVisible: true,
        });
        if (navigateFrom == "cancel") {
            this.postPaymentData(navigateFrom);
        }
        else{
            this.setState({
                loadPanelVisible: true
            });
            let paymentRequestSubmitRequest: AddEditPaymentReqSubmitRequest;
            paymentRequestSubmitRequest = this.utils.convertFormDataToPaymentRequest(this.state.paymentViewDataItem, "save");
            this.paymentService
                .postPaymentRequestValidateData(paymentRequestSubmitRequest)
                .then(this.handlePaymentCheckSuccess)
                .catch((err) => {
                    this.setState({
                        errorMessage:
                            err.response && err.response.data && err.response.data.error
                                ? JSON.parse(JSON.stringify(err.response.data.error))
                                : null,
                        IsShowSuccess: false,
                    });
                    this.failureMessageVisible();
                    this.hideLoadpanel();
                })
                .finally(() => {
                    this.hideLoadpanel();
                });
        }        

    };

    onClickEditRequest = () => {
        this.setState({
            isViewMode: false,
            isEditClicked: true
        }, () => { this.props.captureEditButtonState(this.state.isEditClicked) });
    }
    onClickSaveRefresh = () => {
        this.setState({
            showSavePopUp: true
        });
    }

    onClickCancel = () => {
        this.setState({
            showCancelPopUp: true
        });
    }

    onClickCancelEdit = () => {
        this.setState({
            isViewMode: true,
            isEditClicked: false,
            paymentViewDataItem: {
                ...this.state.paymentViewDataItem,
                clientBusinessEntityId: this.state.clientBusinessEntityId,
                venueId: this.state.venueId,
                serviceSubTypeId: this.state.serviceSubTypeId,                
                typeLookUpId: this.state.typeId,
                startTime: this.state.startTime,
                finishTime: this.state.finishTime,
                description: this.state.description,
                dateFrom: this.state.date,
                providerPayAmountLocal: this.state.artistFee,
                providerPayAmountLocalExtras: this.state.extras
            }
        }, () => { this.props.captureEditButtonState(this.state.isEditClicked) });
    }

    handlePaymentCheckSuccess = (response: AxiosResponse<any>) => {
        let serverResponse: any = response.data.data.data;
        this.hideLoadpanel();
        this.setState({
            IsShowError: false,
        });
        if (serverResponse.length > 0) {
            this.setState({
                showDuplicateItem: true,
                DuplicateResponse: serverResponse
            });
        }
        else {
            this.postPaymentData("save");
        }
    };

    onProceed = () => {
        this.setState({
            showSavePopUp: false,
        });
        this.postPaymentData("save");
        this.hideDuplicateItem();
    };

    onContentReadyNumberBox = (e: any) => {
        this.onClickElementSelection(e);
    }

    onClickElementSelection = (e: any) => {
        e.element.addEventListener('click', function () {
            let inputElement = e.element.querySelector(".dx-texteditor-input");
            inputElement.selectionStart = 0;
            inputElement.selectionEnd = inputElement.value.length;
        });
    }


    render() {
        let heading: string = ""
        let showOtherDesc: boolean = false;
        let showOtherDescEnt: boolean = false;
        let showType: boolean = true;
        let isRaiseQuery: boolean = false;
        if (this.state.paymentViewDataItem.serviceTypeId == ServiceType.Maintenance || this.state.paymentViewDataItem.serviceTypeId == ServiceType.Miscelleanous 
            || (this.state.paymentViewDataItem.serviceTypeId == ServiceType.ContractCleaning && this.state.paymentViewDataItem.serviceSubTypeId == SubServiceType.WeeklyCleaning) 
            || (this.state.paymentViewDataItem.serviceTypeId == ServiceType.Entertainment && this.state.paymentViewDataItem.serviceSubTypeId !== "31" && this.state.paymentViewDataItem.serviceSubTypeId !== "32" && this.state.paymentViewDataItem.serviceSubTypeId !== "34")) {
            showType = false;
        }                 
        if ((this.state.paymentViewDataItem.serviceTypeId == ServiceType.ContractCleaning && this.state.paymentViewDataItem.serviceSubTypeId == SubServiceType.AdditionalCleaning && this.state.paymentViewDataItem.typeLookUpId == "227") ||
            (this.state.paymentViewDataItem.serviceTypeId == ServiceType.WindowCleaning && this.state.paymentViewDataItem.serviceSubTypeId == "108" && this.state.paymentViewDataItem.typeLookUpId == "516") || 
            (this.state.paymentViewDataItem.serviceTypeId == ServiceType.Gardening && this.state.paymentViewDataItem.serviceSubTypeId == "106" && this.state.paymentViewDataItem.typeLookUpId == "405")) {
                showOtherDesc = true;
        }
        if ((this.state.paymentViewDataItem.serviceTypeId == ServiceType.Entertainment && this.state.paymentViewDataItem.serviceSubTypeId == "50") ||
            (this.state.paymentViewDataItem.serviceTypeId == ServiceType.Maintenance && this.state.paymentViewDataItem.serviceSubTypeId == "83") || 
            (this.state.paymentViewDataItem.serviceTypeId == ServiceType.Miscelleanous && this.state.paymentViewDataItem.serviceSubTypeId == "83")){
                showOtherDescEnt = true;
        }
        if (this.state.paymentViewDataItem.paymentRequestTypeID == PaymentTypes.AddJob) {
            heading = "Artist Job Notification Details";
        }
        else {
            heading = "Payment Requests Details";
        }
        let isReadableFasterFee: boolean = true;
        if (this.state.editViewDataItem.fasterPayment == "true" || this.state.editViewDataItem.fasterPayment == "Yes") {
            isReadableFasterFee = false;
        }
        if(this.state.editViewDataItem.paymentRequestStatusLookUp.id == "40" || this.state.editViewDataItem.paymentRequestStatusLookUp.id == "50" || 
            this.state.editViewDataItem.paymentRequestStatusLookUp.id == "99" || this.state.editViewDataItem.paymentRequestStatusLookUp.id == "63" || 
            this.state.editViewDataItem.paymentRequestStatusLookUp.id == "64" || this.state.editViewDataItem.paymentRequestStatusLookUp.id == "69" || 
            this.state.editViewDataItem.paymentRequestStatusLookUp.id == "79"){
                isRaiseQuery = true;
        }
        return (
            <section className="card card-form my-5" >
                <div className="card-body">
                    <h3 className="card-form__section-heading">{heading}</h3>
                    <hr className="horizontal_border_style"></hr>
                    <div className="row mx-0">
                        <div className="col-12 col-lg-3 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Provider
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <TextBox
                                    readOnly={true}
                                    value={this.state.editViewDataItem.providerBusinessEntity ? this.state.editViewDataItem.providerBusinessEntity.shortName : ""}
                                ></TextBox>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Client
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <DropDownFromLookupTable
                                    lookupTypeIndex={LookupTypeIndexes.clientType}
                                    onValueChanged={this.handleChangeClientTypeSelect}
                                    value={this.state.paymentViewDataItem.clientBusinessEntityId}
                                    isReadOnly={this.state.isViewMode}
                                />
                                <input
                                    data-testid={LookupTypeIndexes.clientType}
                                    type="hidden"
                                    name={LookupTypeIndexes.clientType}
                                    value={this.state.paymentViewDataItem.clientBusinessEntityId}
                                ></input>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Venue
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <LookupTypeListDynamic
                                    lookupTypeIndex={LookupTypeIndexes.venueType}
                                    onValueChanged={this.handleChangeVenueTypeSelect}
                                    isRoot={false}
                                    parentMappingId={this.state.paymentViewDataItem.clientBusinessEntityId}
                                    value={this.state.paymentViewDataItem.venueId}
                                    displayExpression={"value"}
                                    isReadOnly={this.state.isViewMode}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Status
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <TextBox
                                    readOnly={true}
                                    value={this.state.editViewDataItem.paymentRequestStatusLookUp.value}
                                ></TextBox>
                            </div>
                        </div>
                    </div>
                    {/* <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} /> */}
                    <div className="row mx-0">
                        <div className="col-12 col-lg-3 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                               {this.state.editViewDataItem.dateFrom == this.state.editViewDataItem.dateTo ? 'Date' :'Date From'}
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <DateBox
                                    onValueChanged={this.handleChangeDate}
                                    value={
                                        this.state.paymentViewDataItem.dateFrom ? this.state.paymentViewDataItem.dateFrom : undefined
                                    }
                                    useMaskBehavior={true}
                                    displayFormat="dd/MM/yyyy"
                                    readOnly={this.state.isViewMode}
                                ></DateBox>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Start/Finish
                            </label>
                            <div className="col-12 col-xl-4 pl-0">
                                <DateBox
                                    type="time"
                                    interval={15}
                                    placeholder="00:00"
                                    useMaskBehavior={true}
                                    displayFormat={"HH:mm"}
                                    readOnly={this.state.isViewMode}
                                    value={
                                        this.state.paymentViewDataItem.startTime
                                            ? this.state.paymentViewDataItem.startTime
                                            : undefined
                                    }
                                    onValueChanged={this.handleChangeStartTime}
                                />
                            </div>
                            <div className="col-12 col-xl-1 pl-0">
                            </div>
                            <div className="col-12 col-xl-4 pl-0">
                                <DateBox
                                    type="time"
                                    interval={15}
                                    placeholder="00:00"
                                    useMaskBehavior={true}
                                    displayFormat={"HH:mm"}
                                    readOnly={this.state.isViewMode}
                                    value={
                                        this.state.paymentViewDataItem.finishTime
                                            ? this.state.paymentViewDataItem.finishTime
                                            : undefined
                                    }
                                    onValueChanged={this.handleChangeFinishTime}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Service
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <TextBox
                                    readOnly={true}
                                    value={this.state.editViewDataItem.serviceType ? this.state.editViewDataItem.serviceType.value : ""}
                                ></TextBox>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Add Job / Payment
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <TextBox
                                    readOnly={true}
                                    value={this.state.editViewDataItem.paymentRequestType.value}
                                ></TextBox>
                            </div>
                        </div>

                    </div>
                    <div className="row mx-0" hidden={this.state.editViewDataItem.dateFrom == this.state.editViewDataItem.dateTo}>
                        <div className="col-12 col-lg-3 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Date To
                            </label>

                            <div className="col-12 col-xl-9 pl-0">
                                <DateBox
                                    onValueChanged={this.handleChangeDateTo}
                                    value={
                                        this.state.paymentViewDataItem.dateTo ? this.state.paymentViewDataItem.dateTo : undefined
                                    }
                                    useMaskBehavior={true}
                                    displayFormat="dd/MM/yyyy"
                                    readOnly={this.state.isViewMode}
                                ></DateBox>
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0" hidden={this.state.editViewDataItem.serviceType.id == ServiceType.Entertainment || 
                        (this.state.editViewDataItem.serviceType.id == ServiceType.Security && this.state.editViewDataItem.serviceSubType.id == SubServiceType.DoorSupervisionPersonnel)}>                        
                        <div className="col-12 col-lg-3 row mx-0 pr-0 mt-3" hidden={this.state.editViewDataItem.serviceSubType.id !== SubServiceType.WeeklyCleaning}>
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Hours
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <NumberBox
                                    onValueChanged={this.handleChangeHours}
                                    valueChangeEvent="keyup"
                                    value={this.state.hours}
                                    onContentReady={this.onContentReadyNumberBox}
                                    step={0}
                                    disabled={false}
                                    readOnly={this.state.isViewMode}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 row mx-0 pr-0 mt-3" hidden={this.state.editViewDataItem.serviceSubType.id !== SubServiceType.WeeklyCleaning}>
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Min
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <SelectBox
                                    dataSource={minuteLookup}
                                    displayExpr="value"
                                    valueExpr="id"
                                    onValueChanged={this.handleChangeMinutes}
                                    value={this.state.minutes}
                                    disabled={false}
                                    readOnly={this.state.isViewMode}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 row mx-0 pr-0 mt-3" hidden={this.state.editViewDataItem.serviceType.id == ServiceType.ContractCleaning && this.state.editViewDataItem.serviceSubType.id == SubServiceType.WeeklyCleaning}>
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Quantity
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <NumberBox
                                    onValueChanged={this.handleChangeQuantity}
                                    valueChangeEvent="keyup"
                                    value={this.state.paymentViewDataItem.quantityOrHours}
                                    step={0}
                                    disabled={false}
                                    readOnly={this.state.isViewMode}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Rate
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <NumberBox
                                    format={{
                                        type: "currency",
                                        precision: 2,
                                        currency: "GBP",
                                    }}
                                    onValueChanged={this.handleChangeRate}
                                    valueChangeEvent="keyup"
                                    value={this.state.paymentViewDataItem.rate}
                                    step={0}
                                    onContentReady={this.onContentReadyNumberBox}
                                    disabled={false}
                                    readOnly={this.state.isViewMode}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0">
                        <div className="col-12 col-lg-3 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Original Artist Fee
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <NumberBox
                                    format={{
                                        type: "currency",
                                        precision: 2,
                                        currency: "GBP",
                                    }}
                                    disabled={false}
                                    onValueChanged={this.handleChangefasterPayOriginalAmount}
                                    valueChangeEvent="keyup"
                                    value={this.state.paymentViewDataItem.providerFasterPayOriginalAmount}
                                    step={0}
                                    onContentReady={this.onContentReadyNumberBox}
                                    readOnly={this.state.isViewMode}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Artist Description
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <TextBox
                                    readOnly={this.state.isViewMode}
                                    value={this.state.paymentViewDataItem.description}
                                    onValueChanged={this.handleChangeDescription}
                                ></TextBox>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Faster Pay Fee
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <NumberBox
                                    format={{
                                        type: "currency",
                                        precision: 2,
                                        currency: "GBP",
                                    }}
                                    onValueChanged={this.handleChangeFasterPayFee}
                                    valueChangeEvent="keyup"
                                    value={this.state.paymentViewDataItem.providerFasterPayFee}
                                    step={0}
                                    onContentReady={this.onContentReadyNumberBox}
                                    readOnly={this.state.isViewMode || isReadableFasterFee}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Adjusted Artist Fee
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <NumberBox
                                    format={{
                                        type: "currency",
                                        precision: 2,
                                        currency: "GBP",
                                    }}
                                    onValueChanged={this.handleChangeProviderLocalCurrancyAmount}
                                    valueChangeEvent="keyup"
                                    value={this.state.paymentViewDataItem.providerPayAmountLocal}
                                    step={0}
                                    onContentReady={this.onContentReadyNumberBox}
                                    disabled={true}
                                    readOnly={this.state.isViewMode}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="w-50 mx-auto">
                            <LoadIndicator
                                id="load-indicator"
                                className="load-indicator"
                                height={40}
                                width={40}
                                visible={this.state.loadIndicatorVisible}
                            />
                        </div>
                    </div>
                    <div className="row mx-0">
                        <div className="col-12 col-lg-3 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Extras
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <NumberBox
                                    format={{
                                        type: "currency",
                                        precision: 2,
                                        currency: "GBP",
                                    }}
                                    onValueChanged={this.handleChangeExtraAmount}
                                    valueChangeEvent="keyup"
                                    value={this.state.paymentViewDataItem.providerPayAmountLocalExtras}
                                    step={0}
                                    onContentReady={this.onContentReadyNumberBox}
                                    readOnly={this.state.isViewMode}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Extras Description
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <TextBox
                                    readOnly={this.state.isViewMode}
                                    value={this.state.paymentViewDataItem.providerPayAmountLocalExtrasDescription}
                                    onValueChanged={this.handleChangeExtrasDescription}
                                ></TextBox>
                            </div>
                        </div>                        
                        <div className="col-12 col-lg-3 row mx-0 pr-0 mt-3">
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Sub Type
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <DropDownFromLookupTableDynamic
                                    lookupTypeIndex={LookupTypeIndexes.subServiceType}
                                    isRoot={true}
                                    parentMappingId={this.state.paymentViewDataItem.serviceTypeId}
                                    onValueChanged={this.handleChangeServiceSubTypeSelect}
                                    isReadOnly={this.state.isViewMode}
                                    value={this.state.paymentViewDataItem.serviceSubTypeId}
                                />
                                <input
                                    data-testid={LookupTypeIndexes.subServiceType}
                                    type="hidden"
                                    name={LookupTypeIndexes.subServiceType}
                                    value={
                                        this.state.paymentViewDataItem.serviceSubTypeId
                                    }
                                ></input>
                            </div>
                        </div>
                        <div  className="col-12 col-lg-3 row mx-0 pr-0 mt-3" hidden={!showType}>
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Type
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <DropDownFromLookupTableDynamic   
                                    lookupTypeIndex={LookupTypeIndexes.offeringsType}
                                    isRoot={false}
                                    parentMappingId={this.state.paymentViewDataItem.serviceSubTypeId}
                                    onValueChanged={this.handleChangeTypeSelect}
                                    isReadOnly={this.state.isViewMode}
                                    value={this.state.paymentViewDataItem.typeLookUpId}
                                />
                                <input
                                    data-testid={LookupTypeIndexes.offeringsType}
                                    type="hidden"
                                    name={LookupTypeIndexes.offeringsType}
                                    value={
                                        this.state.paymentViewDataItem.typeLookUpId
                                            ? this.state.paymentViewDataItem.typeLookUpId
                                            : ""
                                    }
                                ></input>
                            </div>
                        </div>   
                        <div className="col-12 col-lg-3 row mx-0 pr-0 mt-3 align-self-end" hidden={!showOtherDescEnt}>
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Other Description
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <TextBox
                                    readOnly={this.state.isViewMode}
                                    value={this.state.paymentViewDataItem.otherDescription}
                                    onValueChanged={this.handleChangeOtherDescription}
                                ></TextBox>
                            </div>
                        </div>                     
                    </div>
                    <div className="row mx-0">
                        <div className="col-12 col-lg-9 row mx-0 pr-0 mt-3"  hidden={!showOtherDesc}>
                            <label className="card-form__label col-12 col-xl-9 text-left text-xl-right">                                
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 row mx-0 pr-0 mt-3 align-self-end" hidden={!showOtherDesc}>
                            <label className="card-form__label col-12 col-xl-3 text-left text-xl-right">
                                Other Description
                            </label>
                            <div className="col-12 col-xl-9 pl-0">
                                <TextBox
                                    readOnly={this.state.isViewMode}
                                    value={this.state.paymentViewDataItem.otherDescription}
                                    onValueChanged={this.handleChangeOtherDescription}
                                ></TextBox>
                            </div>
                        </div>
                    </div>
                    {this.renderNotificationMessage()}
                    <section className="card-form__button-container">
                        {this.state.isViewMode && this.state.isEditClicked == false ?
                            <>
                                <div className="col-12 col-lg-3 col-xl-2">
                                    <button
                                        className="btn btn-primary btn--large"
                                        type="submit"
                                        onClick={(e: MouseEvent) => {
                                            e.preventDefault();
                                            this.onClickEditRequest();
                                        }}
                                    >
                                        Edit Request
                                    </button>
                                </div>
                                <div className="col-12 col-lg-3 col-xl-2">
                                    <button
                                        className="btn btn--ghost btn--ghost--teal btn--large"
                                        onClick={(e: MouseEvent) => {
                                            e.preventDefault();
                                            this.onClickCancel();
                                        }}
                                    >
                                        Cancel Request
                                    </button>
                                </div>
                                <div className="col-12 col-lg-3 col-xl-2">
                                    <button
                                        className="btn btn--ghost btn--ghost--teal btn--large"
                                        onClick={(e: MouseEvent) => {
                                            e.preventDefault();
                                            this.onSubmitQuery();
                                        }}
                                        disabled={isRaiseQuery}
                                    >
                                        Raise Query
                                    </button>
                                </div>
                            </>
                            :
                            <>
                                <div className="col-12 col-lg-3 col-xl-2">
                                    <button
                                        className="btn btn-primary btn--large"
                                        type="submit"
                                        onClick={(e: MouseEvent) => {
                                            e.preventDefault();
                                            this.onClickSaveRefresh();
                                        }}
                                    >
                                        Save & Refresh
                                    </button>
                                </div>
                                <div className="col-12 col-lg-3 col-xl-2">
                                    <button
                                        className="btn btn-primary btn--large"
                                        type="submit"
                                        onClick={(e: MouseEvent) => {
                                            e.preventDefault();
                                            this.onClickCancelEdit();
                                        }}
                                    >
                                        Cancel Edit
                                    </button>
                                </div>
                            </>
                        }
                    </section>
                    <Popup
                        wrapperAttr={{
                            class: "pending-request-panel__raise-query-pop-up",
                        }}
                        visible={this.state.showSavePopUp}
                        onHiding={this.onSaveClose}
                        dragEnabled={false}
                        hideOnOutsideClick={false}
                        showTitle={true}
                        showCloseButton={true}
                        title="Save changes and update comment (as reqd.)"
                        maxWidth="600px"
                        height="auto"
                    >
                        <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                        <ScrollView width="100%" height="100%">
                            <div className="pending-request-panel__raise-query">
                                <div className="pending-request-panel__query-box">
                                    {this.state.errorMessage && this.state.errorMessage.length > 0 && this.state.errorMessage != null ? (
                                        <span className="unscheduled-shift">
                                            <ul>
                                                {this.state.errorMessage.map((item: any, uniqueKey: number) => {
                                                    return (
                                                        <li key={uniqueKey}>
                                                            {item.columnName}: {item.errorMessage}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </span>
                                    ) : null}
                                    <div className="card-form__subheading ">Internal Comments
                                        <sup className="card-form__mandatory-symbol">*</sup></div>
                                    <TextArea
                                        onValueChanged={this.handleChangeInternalComments}
                                        value={this.state.paymentViewDataItem.internalComments}
                                        height="50%"
                                    />
                                    <div className="row">
                                        <div className={"col-12 col-lg-6"} >
                                            <button className="btn btn-primary btn--large"
                                                onClick={(e: MouseEvent) => {
                                                    e.preventDefault();
                                                    this.onSubmit("save");
                                                }}
                                            >
                                                Save Changes
                                            </button>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <button className="btn btn--ghost btn--ghost--teal btn--large"
                                                onClick={this.onSaveClose}
                                            >
                                                Close
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ScrollView>
                    </Popup>

                    <Popup
                        wrapperAttr={{
                            class: "pending-request-panel__raise-query-pop-up",
                        }}
                        visible={this.state.showCancelPopUp}
                        onHiding={this.onCancelClose}
                        dragEnabled={false}
                        hideOnOutsideClick={false}
                        showTitle={true}
                        showCloseButton={true}
                        title="Confirm cancelling request and update comment"
                        maxWidth="600px"
                        height="auto"
                    >
                        <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                        <ScrollView width="100%" height="100%">
                            <div className="pending-request-panel__raise-query">
                                <div className="pending-request-panel__query-box">
                                    {this.state.errorMessage && this.state.errorMessage.length > 0 && this.state.errorMessage != null ? (
                                        <span className="unscheduled-shift">
                                            <ul>
                                                {this.state.errorMessage.map((item: any, uniqueKey: number) => {
                                                    return (
                                                        <li key={uniqueKey}>
                                                            {item.columnName}: {item.errorMessage}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </span>
                                    ) : null}
                                    <div className="card-form__subheading ">Internal Comments
                                        <sup className="card-form__mandatory-symbol">*</sup></div>
                                    <TextArea
                                        onValueChanged={this.handleChangeInternalComments}
                                        value={this.state.paymentViewDataItem.internalComments}
                                        height="50%"
                                    />
                                    <div className="row">
                                        <div className={"col-12 col-lg-6"} >
                                            <button className="btn btn-primary btn--large"
                                                onClick={(e: MouseEvent) => {
                                                    e.preventDefault();
                                                    this.onSubmit("cancel");
                                                }}
                                            >
                                                Cancel Request
                                            </button>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <button className="btn btn--ghost btn--ghost--teal btn--large" onClick={this.onCancelClose}>
                                                Close
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ScrollView>
                    </Popup>

                    <Popup
                        wrapperAttr={{
                            class: "pending-request-panel__raise-query-pop-up",
                        }}
                        visible={this.state.showRaiseQueryPopUp}
                        onHiding={this.onRaiseQueryClose}
                        dragEnabled={false}
                        hideOnOutsideClick={false}
                        showTitle={true}
                        showCloseButton={true}
                        title="Raise Query"
                        maxWidth="600px"
                        height="auto"
                    >
                        <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                        <ScrollView width="100%" height="100%">
                            <div className="pending-request-panel__raise-query">
                                <div className="pending-request-panel__query-box">
                                    {this.state.validationMessage != "" ? (
                                        <>
                                            {" "}
                                            <br />{" "}
                                            <span className="text-danger font-weight-bold justify-content-center">
                                                <ul>
                                                    <li>
                                                        {this.state.validationMessage}
                                                    </li>
                                                </ul>
                                            </span>{" "}
                                        </>
                                    ) : null}
                                    <div className="card-form__subheading ">Internal Comments
                                        <sup className="card-form__mandatory-symbol">*</sup></div>
                                    <TextArea
                                        onValueChanged={this.handleChangeQueryDescription}
                                        value={this.state.queryItem.queryDescription}
                                        height="50%"
                                    />
                                    <div className="row">
                                        <div className={"col-12 col-lg-6"} >
                                            <button className="btn btn-primary btn--large"
                                                onClick={(e: MouseEvent) => {
                                                    e.preventDefault();
                                                    this.onClickRaiseQuery();
                                                }}
                                            >
                                                Raise Query
                                            </button>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <button className="btn btn--ghost btn--ghost--teal btn--large" onClick={this.onRaiseQueryClose}>
                                                Close
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ScrollView>
                    </Popup>
                    <Popup
                        visible={this.state.showDuplicateItem}
                        onHiding={this.hideDuplicateItem}
                        dragEnabled={false}
                        hideOnOutsideClick={true}
                        showTitle={true}
                        showCloseButton={true}
                        title={"Warning – Possible Duplicates Exist – Please click PROCEED to add this request or else CANCEL"}
                        resizeEnabled={true}
                        maxWidth={1200}
                        height={400}
                    >
                        <ScrollView width='95%' height='100%' direction={"both"}>
                            <article className="grid-info d-flex flex-row">
                                {this.state.DuplicateResponse.map((item: any, uniqueKey: number) => {
                                    return (
                                        <MatchedCard data={item} dateFrom={this.state.paymentViewDataItem.dateFrom} venueId={this.state.paymentViewDataItem.venueId} providerBusinessEntityId={this.state.paymentViewDataItem.providerBusinessEntityId} providerFasterPayOriginalAmount={this.state.paymentViewDataItem.providerFasterPayOriginalAmount} />
                                    );
                                })}
                            </article>
                            <div className="row px-3 w-50">
                                <div className="col-12 col-xl-6 pl-0">
                                    <button
                                        className="btn btn-primary btn--large mt-3"
                                        type="submit"
                                        onClick={(e: MouseEvent) => {
                                            e.preventDefault();
                                            this.onProceed();
                                        }}
                                    >
                                        Proceed
                                    </button>
                                </div>
                                <div className="col-12 col-xl-6 pl-0">
                                    <button
                                        className="btn btn--ghost btn--ghost--teal btn--large mt-3"
                                        onClick={(e: MouseEvent) => {
                                            e.preventDefault();
                                            this.hideDuplicateItem();
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </ScrollView>
                    </Popup>
                </div>
            </section >
        );
    }
}

export default withTranslation()(MatchedPopUp);
