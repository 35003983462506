import { AxiosResponse } from "axios";
import { _get, _aiTraceAsError } from "./BaseApiService";

// Endpoints
const LOOKUP_BASE_URL = "lookup";
const LOOKUP_BASE_URL_BY_ENTITY = "BankAccount";


/**
 * Must be included with any component that needs to perform a lookup
 * The index values are used to
 *  1) determine which lookup to fetch
 *  2) build the API string - the string values are the api names
 */
export const LookupTypeIndexes = {
    fileFormatType: "fileFormat",
    serviceType: "service",
    subServiceType: "subservice",
    clientType: "client",
    paymentTypeLookUp: "paymentCycleType",
    providerType: "provider",
    venueType: "venue",
    statusType: "status",
    currencytype: "currencytype",
    decisionType: "decisionType",
    offeringsType: "offerings",
    identificationType: "identificationtype",
    defaultType: "defaultTypeId",
    configuration: "configuration",
    unitOfMeasure: "unitofmeasure",
    periodMatrix: "periodMatrix",
    invoicestatus: "invoicestatus",
    paymentStatus: "paymentcyclestatus",
    itemType: "itemtype",
    contactType: "contact",
    contactTypeDetails: "contacttype",
    paymentStatusType: "paymentstatus",
    frequencyType: "frequency",
    dayType: "Days",
    fileType: "fileType",
    lookuptype: "businessentitylegalstatus",
    linktype: "linktype",
    showintereststatus: "showintereststatus",
    servicefeecalculationtype: "servicefeecalculationtype",
    incidentStatus: "incidentstatus",
    allClient: "allclient",
    bulletincategory: "bulletincategory",
    bulletinpriority: "bulletinpriority",
    billableitemuploadhdroverride: "billableitemuploadhdroverride",
    userCompanyType: "businessentityshortname",
    userRoleType: "role",
    userContactType: "contacttype",
    userDeviceApp: "userdeviceapp",
    accrualStatus: "accrualstatus",
    faqCategory: "faqscategory",
    requeststatuslookup: "requeststatuslookup",
    query: "query",
    querytype: "querytype",
    activity: "activity",
    cancellationReason: "CancellationReason",
    Genre: "Genre",
    MusicType: "MusicType",
    budgetType: "budgettype",
    workerstatus: "workerstatus",
    paymentrequesttype: "paymentrequesttype",
    paymentrequeststatus: "paymentrequeststatus",
    venueTypeLookUp:"venuetype",
    equipmentType: "equipmenttype",
    originator: "originator",
    djStyle: "djStyle",
    organisationType: "organisationtype",
    contactCompanyType: "company",
    documentTypeLookup: "documenttypelookup",
    providerLegalNameLookup:'providerlegalname',
    clientLegalNameLookup:'clientlegalname',
    approvalMode:'approvalmode'
};

export const UnitOfMeasureTypes = {
    time: "Time",
    quantity: "Quantity",
};
//This will serve to figure out what should be displayed on the dropdown from the object that is returned.
export const dropdownFilteredValue = {
    sortCode: "sortCode",
    accountNumber: "accountNumber",
};

// Standard Lookup shape
export interface LookupTypeItem {
    id: string;
    value: string;
    parentMappingId: string;
}

export interface PeriodMatrixLookupItem {
    businessEntityId: string;
    businessEntityShortName: string;
    dateFrom: string;
    dateTo: string;
    daysOfWeekId: string;
    daysOfWeekValue: string;
    id: string;
    isActive: boolean;
    lastUpdatedBy: string;
    lastUpdatedOn: string;
    periodNumber: string;
    periodWeek: string;
    yearNumber: string;
}

export interface VenueLookupTypeItem {
    id: string;
    value: string;
    postCode: string;
    parentMappingId: string;
    venueTypeId:string;
}
//Lookup object to retrieve the Bank information.
export interface LookupTypeItemForEntityId {
    id: string;
    businessEntityID: string;
    contactID: string;
    bankName: string;
    branchName: string;
    accountNumber: string;
    sortCode: string;
    buildingSociety: string;
    nameOnAccount: string;
    active: string;
    serviceTypeLookUpId: string;
    serviceSubTypeLookUpId: string;
}

/// REST service for lookups.
class lookupService {
    /**
     * Predicate function to determine if the selected id is the correct unit of measure
     *
     * @param subTypeId {string} - selected subType Id
     * @param unitOfMeasureType {string} - desired Unit of Measure (Time vs Quantity)
     * @param unitOfMeasureList {LookupTypeItem[]} - Lookup of all unit of measure values
     * @returns {boolean} - True if the id is the correct unit of measure
     */
    isSubTypeIdEqualToUnitOfMeasureType(
        subTypeId: string,
        unitOfMeasureType: string,
        unitOfMeasureList: LookupTypeItem[]
    ): boolean {
        var itemsInList = unitOfMeasureList.filter((item: LookupTypeItem) => {
            return item.parentMappingId === subTypeId && item.value === unitOfMeasureType;
        });
        return itemsInList.length > 0;
    }
    /**
     *
     *
     * @param lookupTypeIndex {string} - LookupTypeIndexes.{index}
     * @returns {Promise<AxiosResponse<any>>} - server resposne
     */
    getLookupByLookupTypeIndex(lookupTypeIndex: string): Promise<AxiosResponse<any>> {
        return _get(LOOKUP_BASE_URL + `?type=${lookupTypeIndex}`);
    }
    /**
     *
     *
     * @param lookupTypeIndex {string} - LookupTypeIndexes.{index}
     * @returns {Promise<AxiosResponse<any>>} - server resposne
     */
    getLookupByLookupTypeIndexAll(lookupTypeIndex: string): Promise<AxiosResponse<any>> {
        return _get(LOOKUP_BASE_URL + `?type=${lookupTypeIndex}` + `&filterBy=${"all"}`);
    }

    getLookupByEntityId(entityId: string): Promise<AxiosResponse<any>> {
        return _get(LOOKUP_BASE_URL_BY_ENTITY + `?businessEntityId=${entityId}`);
    }

    getDefaultType(): any {
        return [];
    }

    getDecisionTypes(): LookupTypeItem[] {
        return [
            {
                id: "1",
                value: "Yes",
                parentMappingId: "0",
            },
            {
                id: "0",
                value: "No",
                parentMappingId: "0",
            },
        ];
    }

    getResidencyEndKnown(): LookupTypeItem[] {
        return [
            {
                id: "1",
                value: "Yes",
                parentMappingId: "0",
            },
            {
                id: "0",
                value: "Until Further Notice",
                parentMappingId: "0",
            },
        ];
    }

    getReplacementArtist(): LookupTypeItem[] {
        return [
            {
                id: "true",
                value: "Yes - Re-Publish Gig Request",
                parentMappingId: "0",
            },
            {
                id: "false",
                value: "No - Cancel Gig Request",
                parentMappingId: "0",
            },
        ];
    }

    getUpdateOrCorrection(): LookupTypeItem[] {
        return [
            {
                id: "true",
                value: "Correction",
                parentMappingId: "0",
            },
            {
                id: "false",
                value: "Update",
                parentMappingId: "0",
            },
        ];
    }

    getNonStandardTypes(): LookupTypeItem[] {
        return [
            {
                id: "1",
                value: "Standard",
                parentMappingId: "0",
            },
            {
                id: "0",
                value: "Non - Standard",
                parentMappingId: "0",
            },
        ];
    }

    getChargeTypes(): LookupTypeItem[] {
        return [
            {
                id: "1",
                value: "Applied",
                parentMappingId: "0",
            },
            {
                id: "0",
                value: "Waived",
                parentMappingId: "0",
            },
        ];
    }

    getDefaultPeriodValues(): LookupTypeItem[] {
        return [
            {
                id: "1",
                value: "1",
                parentMappingId: "0",
            },
            {
                id: "2",
                value: "2",
                parentMappingId: "0",
            },
            {
                id: "3",
                value: "3",
                parentMappingId: "0",
            },
            {
                id: "4",
                value: "4",
                parentMappingId: "0",
            },
            {
                id: "5",
                value: "5",
                parentMappingId: "0",
            },
            {
                id: "6",
                value: "6",
                parentMappingId: "0",
            },
            {
                id: "7",
                value: "7",
                parentMappingId: "0",
            },
            {
                id: "8",
                value: "8",
                parentMappingId: "0",
            },
            {
                id: "9",
                value: "9",
                parentMappingId: "0",
            },
            {
                id: "10",
                value: "10",
                parentMappingId: "0",
            },
            {
                id: "11",
                value: "11",
                parentMappingId: "0",
            },
            {
                id: "12",
                value: "12",
                parentMappingId: "0",
            },
            {
                id: "13",
                value: "13",
                parentMappingId: "0",
            },
            {
                id: "14",
                value: "14",
                parentMappingId: "0",
            },
            {
                id: "15",
                value: "15",
                parentMappingId: "0",
            },
            {
                id: "16",
                value: "16",
                parentMappingId: "0",
            },
            {
                id: "17",
                value: "17",
                parentMappingId: "0",
            },
            {
                id: "18",
                value: "18",
                parentMappingId: "0",
            },
        ];
    }

    getAbsentTypes(): LookupTypeItem[] {
        return [
            {
                id: "1",
                value: "Present",
                parentMappingId: "0",
            },
            {
                id: "0",
                value: "Not Present",
                parentMappingId: "0",
            },
        ];
    }

    getPeriodWeek(): LookupTypeItem[] {
        return [
            {
                id: "1",
                value: "1",
                parentMappingId: "0",
            },
            {
                id: "2",
                value: "2",
                parentMappingId: "0",
            },
            {
                id: "3",
                value: "3",
                parentMappingId: "0",
            },
            {
                id: "4",
                value: "4",
                parentMappingId: "0",
            },
            {
                id: "5",
                value: "5",
                parentMappingId: "0",
            },
        ];
    }
    getMonth(): LookupTypeItem[] {
        return [
            {
                id: "0",
                value: "JAN",
                parentMappingId: "0",
            },
            {
                id: "1",
                value: "FEB",
                parentMappingId: "0",
            },
            {
                id: "2",
                value: "MAR",
                parentMappingId: "0",
            },
            {
                id: "3",
                value: "APR",
                parentMappingId: "0",
            },
            {
                id: "4",
                value: "MAY",
                parentMappingId: "0",
            },
            {
                id: "5",
                value: "JUN",
                parentMappingId: "0",
            },
            {
                id: "6",
                value: "JUL",
                parentMappingId: "0",
            },
            {
                id: "7",
                value: "AUG",
                parentMappingId: "0",
            },
            {
                id: "8",
                value: "SEP",
                parentMappingId: "0",
            },
            {
                id: "9",
                value: "OCT",
                parentMappingId: "0",
            },
            {
                id: "10",
                value: "NOV",
                parentMappingId: "0",
            },
            {
                id: "11",
                value: "DEC",
                parentMappingId: "0",
            },
        ];
    }

    getAccrualTypes(): LookupTypeItem[] {
        return [
            {
                id: "1",
                value: "Include",
                parentMappingId: "0",
            },
            {
                id: "0",
                value: "Don't Include",
                parentMappingId: "0",
            },
        ];
    }

    getGroupAccrualTypes(): LookupTypeItem[] {
        return [
            {
                id: "1",
                value: "Group",
                parentMappingId: "0",
            },
            {
                id: "0",
                value: "Don't Group",
                parentMappingId: "0",
            },
        ];
    }
    getSpecificStatusTypes(): LookupTypeItem[] {
        return [
            {
                id: "1",
                value: "ALL",
                parentMappingId: "0",
            },
            {
                id: "2",
                value: "Pending",
                parentMappingId: "0",
            },
            {
                id: "3",
                value: "Approved",
                parentMappingId: "0",
            },
            {
                id: "4",
                value: "Billed",
                parentMappingId: "0",
            },
            {
                id: "5",
                value: "Not Billed",
                parentMappingId: "0",
            },
            {
                id: "6",
                value: "Paid",
                parentMappingId: "0",
            },
            {
                id: "7",
                value: "Not Paid",
                parentMappingId: "0",
            },
        ];
    }
    getSeverity(): LookupTypeItem[] {
        return [
            {
                id: "0",
                value: "High",
                parentMappingId: "0",
            },
            {
                id: "1",
                value: "Medium",
                parentMappingId: "0",
            },
            {
                id: "2",
                value: "Low",
                parentMappingId: "0",
            },
        ];
    }
    // Add this method to the lookupService class
    getOfferingsFiltered(filterBy:string): Promise<AxiosResponse<any>> {
    const lookupTypeIndex = "offerings";
    //const filterBy = "10,20,30,40,50,60,80";
    return _get(`${LOOKUP_BASE_URL}?type=${lookupTypeIndex}&filterby=${filterBy}`);
    }

    getArtistTypes(lookupTypeIndex: string, filterBy: string , navigateFrom: string): Promise<AxiosResponse<any>> {
        return _get(`${LOOKUP_BASE_URL}?type=${lookupTypeIndex}&filterby=${filterBy}&navigatefrom=${navigateFrom}`);
    }
    //For New Request form
    getServiceSubType(lookupTypeIndex: string, filterBy: string): Promise<AxiosResponse<any>> {
        return _get(`${LOOKUP_BASE_URL}?type=${lookupTypeIndex}&filterby=${filterBy}`);
    }

    // get vat config value
    getVATValue(lookupTypeIndex: string, filterBy: string): Promise<AxiosResponse<any>> {
        return _get(`${LOOKUP_BASE_URL}?type=${lookupTypeIndex}&filterby=${filterBy}`);
    }
    /**
     * Exposing function from BaseApiService only so that the calling component does not need to include both this service and the base service.
     * The idea here is that removing appInsights or adding this function to existing components can be done with little disruption.
     *
     * @param message {string}
     */
    traceAsErrorToAppInsights(message: string): boolean {
        return _aiTraceAsError(message);
    }
}

export default lookupService;
