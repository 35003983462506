import React from "react";
import sharedUtils from "../grid/sharedUtilities";
import { AxiosResponse } from "axios";
import ManageDocumentsGridService from "../../services/ManageDocumentsGridService";
import { NullableDate } from "../grid/AddEditPopUpUtilities";
import paymentFormUtils, { ActionType, PaymentFormData } from "../Payments/PaymentFormUtils";
import PaymentService, { paymentCycleStatus, paymentStatus } from "../../services/PaymentService";
import { DateBox, LoadPanel } from "devextreme-react";
import PaymentBACSGrid from "../Payments/BACS/BacsGrid";
import { DateBoxOnValueChangedEvent } from "../../types/DevExtremeTypes";
import NewAccountsPaymentsGrid from "./NewAccountsPaymentGrid";

interface SendToAccountsPaymentsProps {
    viewDisabled:boolean
    paymentCycleId: number;
    paymentData: PaymentFormData;
    onSendToAccounts: (status: string, statusId: string, isRestartDisable: boolean) => void;
    statusId: string;
    invoiceSentDate: NullableDate;
    syncDateFields: (date: NullableDate, type: string) => void;
    calculationsObject: any;
    location: any;
    history: any;
}

interface SendToAccountsPaymentsState {
    loadPanelVisible: boolean;
    disableSentButton: boolean;
    statusIdOnClick: string;
    downloadLink: string;
    downloadLinkCss: string;
    paymentFormData: PaymentFormData;
    errorMessage: [];
    gridRefreshSignal: boolean;
    paymentRefresh: boolean;
    showChildComponent:boolean;
}

class SendToAccountsPayments extends React.Component<SendToAccountsPaymentsProps> {
    state: SendToAccountsPaymentsState;
    PaymentService: PaymentService;
    paymentFormUtils: paymentFormUtils;
    sharedUtils: sharedUtils;
    documentService: ManageDocumentsGridService;

    constructor(props: SendToAccountsPaymentsProps) {
        super(props);
        this.PaymentService = new PaymentService();
        this.paymentFormUtils = new paymentFormUtils();
        this.sharedUtils = new sharedUtils();
        this.state = {
            loadPanelVisible: false,
            disableSentButton: this.props.viewDisabled ? true : false,
            statusIdOnClick: "",
            downloadLink: "",
            downloadLinkCss: "icon-btn",
            paymentFormData: this.props.paymentData,
            errorMessage: [],
            gridRefreshSignal: false,
            paymentRefresh: false,
            showChildComponent:false
        };
        this.documentService = new ManageDocumentsGridService();
    }

    componentDidUpdate = (prevProps: SendToAccountsPaymentsProps) => {
    };

    handleChangeSentDate = (dxValueChange: DateBoxOnValueChangedEvent) => {
        this.setState({
            paymentFormData: {
                ...this.state.paymentFormData,
                paySentDate: dxValueChange.value,
            },
        });
    };
    sendToAccounts = (statusId: number) => {
            this.setState({
                loadPanelVisible: true,
                statusIdOnClick:paymentStatus.SendToAccounts
            }, () => {
               //API call for send to accounts
               this.PaymentService.SendPurchaseInvoice(statusId)
            .then(this.handleSuccessOnSent)
            .catch(this.handleFailure);
            });
    }
    handleSuccessOnSent = (response: AxiosResponse<any>) => {
        const { statusIdOnClick } = this.state;
                this.setState(
                    (prevState: SendToAccountsPaymentsState) => ({
                        loadPanelVisible: false,
                        disableSentButton: statusIdOnClick == paymentStatus.NotSendToAccounts ||statusIdOnClick == paymentStatus.SendToAccounts? true :  false,
                        errorMessage: [],
                        showChildComponent: true
                    }),
                    () => {
                        this.props.onSendToAccounts(paymentStatus.SendToAccounts, statusIdOnClick,false)
                    });
    };
    handleFailure = (error: any) => {
        var { statusIdOnClick } = this.state;
                var respMessage: string = "SendPurchaseInvoice failed";
        
                if (!this.PaymentService.traceAsErrorToAppInsights(respMessage)) {
                    // AppInsights is not available
                    console.error(respMessage);
                }
                this.setState({
                    errorMessage:  error.response !== null ? respMessage : null,
                    loadPanelVisible: false,
                    disableSentButton: false,
                    downloadLinkCss:
                        statusIdOnClick == paymentCycleStatus.NotSendToAccounts || paymentCycleStatus.SendToAccounts ? "disabled icon-btn" : "icon-btn",
                });
    };
    SkipToAccounts = (statusId: string, status: string) => {
        let data: PaymentFormData = this.paymentFormUtils.paymentDataInitializePaymentItems(
                    this.props,
                    statusId,
                    status,
                    this.props.paymentData.paySentDate
                );
        this.setState({
            gridData: data,
            loadPanelVisible: true,
            statusIdOnClick: statusId,
        });
        this.onSubmit(data);
    }
    onSubmit = (paymentData: PaymentFormData) => {
            var paymentCycleSubmitRequest = this.paymentFormUtils.convertFormDataToPaymentCycleRequest(
                paymentData,
                ActionType.Save,
                "",
                ""
            );
            this.PaymentService.postPaymentCycleDataV2(paymentCycleSubmitRequest)
                .then(this.handleSuccess)
                .catch(this.handleFailure);
        };
        handleSuccess = (response: AxiosResponse<any>) => {
                const { statusIdOnClick } = this.state;
                this.setState(
                    (prevState: SendToAccountsPaymentsState) => ({
                        loadPanelVisible: false,
                        disableSentButton: statusIdOnClick == paymentStatus.NotSendToAccounts ||statusIdOnClick == paymentStatus.SendToAccounts? true :  false,
                        downloadLinkCss: "icon-btn",
                        errorMessage: [],
                    }),
                    () => this.props.onSendToAccounts(paymentStatus.NotSendToAccounts, statusIdOnClick, false)
                );
            };
    render() {
        var viewDisabled = this.props.location.state?.viewDisabled;
        var { statusId } = this.props;
        let { disableSentButton } = this.state;
        var disablePaymentSentButton: boolean = statusId != paymentStatus.PaymentPackReady ? false : true;
        var sentButtonCssClass: string = "btn disabledCycleButtonColor btn--large";
        var sentButtonDisabled: boolean = true;
        if (disablePaymentSentButton && disableSentButton == false) {
            sentButtonDisabled = false;
            sentButtonCssClass = "btn saveCycleButtonColor btn--large";
        }
        return (
            <div className="card my-3">
                <div className="card-body">
                    <h4>
                        <b>Send to Accounts</b>
                    </h4>
                    <>
                        <br></br>
                        <h5>Send to Business Central System</h5>
                        <PaymentBACSGrid
                            location={this.props.location}
                            history={this.props.history}
                            refreshSignal={this.state.gridRefreshSignal}
                            gridType={this.state.paymentFormData.paymentCycleTypeId}
                            id={this.props.paymentCycleId.toString()}
                        />
                         {this.state.errorMessage ? (
                            <span className="unscheduled-shift">
                                <ul>
                                   <li>{this.state.errorMessage}</li>
                                </ul>
                            </span>
                        ) : (
                            <></>
                        )}
                        <div className="row align-items-center">
                            <div className="col-2">
                                <button
                                    className={(viewDisabled ? "disabled " : "") + sentButtonCssClass}
                                    type="button"
                                    onClick={(e: React.MouseEvent) => {
                                        e.preventDefault();
                                        this.sendToAccounts(this.props.paymentCycleId)
                                    }}
                                    disabled={viewDisabled ? true : sentButtonDisabled}
                                >
                                    SEND TO ACCOUNT
                                </button>
                            </div>
                            <div className="col-2">
                                <button
                                    className={(viewDisabled ? "disabled " : "") + sentButtonCssClass}
                                    type="button"
                                    onClick={(e: React.MouseEvent) => {
                                        e.preventDefault();
                                        this.SkipToAccounts(paymentStatus.NotSendToAccounts, paymentCycleStatus.NotSendToAccounts);
                                    }}
                                    disabled={viewDisabled ? true : sentButtonDisabled}
                                >
                                    SKIP
                                </button>
                            </div>
                        </div>
                        <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                        {this.state.showChildComponent && (<NewAccountsPaymentsGrid 
                            id={this.props.paymentCycleId.toString()}
                            paymentsRefresh={this.state.paymentRefresh}
                        />)}
                    </>
                </div>
            </div>
        );
    }
}
export default SendToAccountsPayments;
