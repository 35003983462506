import { AxiosResponse } from "axios";
import { _get, _post, _aiTraceAsError } from "./BaseApiService";
import { invoiceServices } from "./BillingCyclesFormService";

// Endpoints
const BILLING_CYCLES_GRID_API = "Invoice"; //Define the Endpoints post the API is ready.
const BILLING_CYCLES_INVOICE_API = "Invoice/"; // Define Endpoint for BillableManageCyle Grid
const POST_BILLING_CYCLE_INVOICE = "Invoice/AddEditResetInvoice";
// Grid row
export interface BillingCyclesGridServiceRowItem {
    id: number;
    client: string;
    total: string;
    tax: string;
    subTotal: string;
    invoiceDate: string;
    invoiceReference: string;
    dateTo: string;
    dateFrom: string;
    period: string;
    status: string;
    action: string;
    invoiceSentDate: string;
    invoiceDueDate: string;
    invoicePaidDate: string;
    invoiceAmount: string;
    description: string;
}

export interface BillingCyclesGridServiceInvoiceItem {
    invoiceId: string;
    clientId: string;
    client: string;
    statusId: string;
    status: string;
    periodMatrixId: string;
    period: string;
    week: string;
    dateRangeFrom: string;
    dateRangeTo: string;
    tax: string;
    subTotal: string;
    total: string;
    invoiceDate: string;
    invoiceReference: string;
    invoiceSentDate: string;
    invoiceDueDate: string;
    invoicePaidDate: string;
    invoiceAmount: string;
    description: string;
    actionType: string;
    billableItemIds: string;
    internalReference: string;
    invoiceServiceMappingList: invoiceServices[];
    includeAccruals: boolean;
    groupAccruals: boolean;
    sentToAccountsDate: string;
}

/// Service
class BillingCyclesGridService {
    getBillingCycleGridRows(): Promise<AxiosResponse<any>> {
        return _get(BILLING_CYCLES_GRID_API);
    }

    getBillingCycleInvoice(id: string): Promise<AxiosResponse<any>> {
        return _get(BILLING_CYCLES_INVOICE_API + id); // will take id as paramater
    }

    postBillingCycleInvoice(data: Object): Promise<AxiosResponse<any>> {
        return _post(POST_BILLING_CYCLE_INVOICE, data);
    }
    //#endregion
    traceAsErrorToAppInsights(message: string): boolean {
        return _aiTraceAsError(message);
    }
}

export default BillingCyclesGridService;
