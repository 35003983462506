import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateExclamation } from "@fortawesome/pro-regular-svg-icons";
import { AxiosResponse } from "axios";
import { Button, LoadPanel } from "devextreme-react";
import gridUtils from "../../grid/GridUtilities";
import sharedUtils from "../../grid/sharedUtilities";
import PaymentService from "../../../services/PaymentService";

interface STAInvoicePaymentProps {
    data: any;
    onRefresh?: (flag:boolean) => void;
    type: string
}

interface STAInvoicePaymentState {
    loadPanelVisible: boolean;
}

class STAInvoicePayment extends React.Component<STAInvoicePaymentProps> {
    state: STAInvoicePaymentState;
    Service: PaymentService;
    gridUtils: gridUtils;
    sharedUtils: sharedUtils;
    constructor(props: STAInvoicePaymentProps) {
        super(props);
        this.state = {
            loadPanelVisible: false,
        };
        this.Service = new PaymentService();
        this.gridUtils = new gridUtils();
        this.sharedUtils = new sharedUtils();
    }

    onRegenerateClick = (event: any) => {
        event.event?.stopPropagation();
        let paymentId = this.props.data.data.paymentId  || '';
        let paymentCycleId = this.props.data.data.paymentCycleId  || '';
        this.setState({
            loadPanelVisible: true
        }, () => {            
            this.Service
            .sendPurchaseInvoicePaymnetDates(paymentCycleId,paymentId,this.props.data.data.sentToAccountsDateInvoice ?true :false)
            .then(this.handleSuccess)
            .catch(this.handleError);
        });
    };

    handleSuccess = (response: AxiosResponse<any>) => {
                this.setState({
                    loadPanelVisible: false
                },()=>{
                this.props.onRefresh ? this.props.onRefresh(true):null;
                });
    };

    handleError = (error: any) => {
        this.setState({
            loadPanelVisible: false
        });
        var respMessage: string = "generateDocument failed with response: " + JSON.stringify(error);

        if (!this.Service.traceAsErrorToAppInsights(respMessage)) {
            // AppInsights is not available
            console.error(respMessage);
        }
    };


    renderInvoiceRegenerateButton = () =>{
        let showRegenerate = true;  //show regenerate in all cases
        if (showRegenerate) {
            return (
                <>
                    <Button className="btn icon-btn btn-file-downloader" onClick={(e) => this.onRegenerateClick(e)}>
                        <FontAwesomeIcon icon={faRotateExclamation} 
                        />
                    </Button>
                </>
            );
        }
    }

    render() {
        return (
            <>
            <div className="d-flex justify-content-between align-items-center">
                {this.props.data.data.sentToAccountsDateInvoice && this.props.type==="invoice"?(
                    <div style={{minWidth:'50px',width:'20%'}}>{this.props.data.data.sentToAccountsDateInvoice}</div>
                ):this.props.data.data.sentToAccountsDatePayment && this.props.type==="payment"?(
                    <div style={{minWidth:'50px',width:'20%'}}>{this.props.data.data.sentToAccountsDatePayment}</div>
                ):(
                <>
                <div style={{minWidth:'50px',width:'20%'}}>{this.renderInvoiceRegenerateButton()}</div>
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    visible={this.state.loadPanelVisible}
                    style={{
                        position: "absolute",
                    }}
                />
                </>
                )}
            </div>
            </>
        );
    }
}

export default STAInvoicePayment;
